<template>
	<el-card class="card" :body-style="{ padding: '20px', 'overflow-y': 'auto', height: 'calc(100% - 106px)' }">
		<template #header>
			<div class="card-header">
				<div class="header-title">
					<el-button @click="cancel"
						><el-icon><arrowleft /></el-icon
					></el-button>
					Order {{ order.orderName }}
					<el-button @click="reloadOrder">
						<el-icon><refresh /></el-icon>
					</el-button>
				</div>
			</div>
		</template>
		<order-details :model="order" :validation-session-log="validationSessionLog" @on-order-changed="reloadOrder"></order-details>
	</el-card>
</template>

<script lang="ts">
import { defineComponent, onMounted, toRefs, reactive, watch } from 'vue';
import router from '@/router';

import OrderDetails from './components/OrderDetails.vue';

import * as orderSvc from './services/order.service';
import { emptyOrder } from './models/order.model';

import { getCertificateRequest } from '../companies/services/certificateRequest.service';

import * as clientSvc from '@/features/clients/services/client.service';
import { ValidationSessionLog } from '../clients/models/validation-session-log.model';

export default defineComponent({
	components: {
		OrderDetails,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		const state = reactive({
			order: emptyOrder,
			validationSessionLog: new Array<ValidationSessionLog>(),
		});

		watch(
			() => props.id,
			async () => {
				await reloadOrder();
			}
		);

		onMounted(async () => {
			await reloadOrder();
		});

		const reloadOrder = async () => {
			const order = await orderSvc.getOrder(Number.parseInt(props.id));
			if(order.certificateRequestId) {
				const certificateRequest = await getCertificateRequest(order.certificateRequestId);
				order.certificateRequest = certificateRequest;
			}
			state.order = order;

			state.validationSessionLog =
				(await clientSvc.getValidationSessionLogs(state.order.client!.id!, state.order.orderSession!.id!)) ||
				new Array<ValidationSessionLog>();
		};

		const cancel = () => {
			router.back();
		};

		return {
			...toRefs(state),
			reloadOrder,
			cancel,
		};
	},
});
</script>

<style scoped lang="scss">
.card {
	height: 100%;
	width: 100%;

	.el-card {
		height: 100%;
	}

	.card-header {
		display: flex;
		font-size: 1.2rem;
		font-weight: 600;
		justify-content: space-between;
		align-items: center;

		.header-title {
			display: flex;
			align-items: center;
			gap: 2rem;
		}

		.header-actions {
			display: flex;
			flex-direction: row;
			gap: 1rem;
			align-items: center;
		}
	}
}
</style>
