declare module '@vue/runtime-core' {
	export interface ComponentCustomProperties {
		i18n: typeof i18nService;
	}
}

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import axios, { AxiosError } from 'axios';
import VueAxios from 'vue-axios';

import ElementPlus from 'element-plus';
import { ElNotification } from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import i18nService from './common/services/i18n.service';
import * as ElementIcons from '@element-plus/icons-vue';

axios.interceptors.response.use(
	function (response) {
		return response;
	},
	function (e) {
		const error = e as AxiosError;
		if (error.response) {
			if (error.response.status === 401) {
				router.push({ name: 'Login' });
				return Promise.resolve();
			} else {
				return Promise.reject(error);
			}
		} else {
			ElNotification({
				title: 'Error',
				type: 'error',
				message: `A network error has occured.`,
				position: 'top-right',
				zIndex: 10000,
			});
			return Promise.reject(error);
		}
	}
);

const reservedNames = [
	{ key: 'filter', replacement: 'icon-filter' },
	{ key: 'link', replacement: 'icon-link' },
	{ key: 'menu', replacement: 'icon-menu' },
	{ key: 'picture', replacement: 'icon-picture' },
	{ key: 'select', replacement: 'icon-select' },
	{ key: 'switch', replacement: 'icon-switch' },
	{ key: 'view', replacement: 'icon-view' },
	{ key: 'hide', replacement: 'icon-hide' },
	{ key: 'uploadfilled', replacement: 'upload-filled' },
];
const app = createApp(App).use(router).use(VueAxios, axios).use(ElementPlus);
for (const [key, component] of Object.entries(ElementIcons)) {
	const componentName = reservedNames.find((f) => f.key === key.toLowerCase())?.replacement || key.toLowerCase();
	app.component(componentName, component);
}

app.config.globalProperties.i18n = i18nService;
i18nService.init('ro-RO', 'en-US').then(() => {
	app.mount('#app');
	i18nService.setCulture('ro-RO');
});
