import { BaseEntity } from './base.model';

export interface Address extends BaseEntity {
	country: string;
	county: string;
	city: string;
	street: string;
	streetNumber: string;
	building: string;
	buildingNumber: string;
	apartment: string;
}

export const emptyAddress: Address = {
	id: 0,
	createdBy: null,
	createdOn: null,
	modifiedBy: null,
	modifiedOn: null,
	isDeleted: false,
	country: '',
	county: '',
	city: '',
	street: '',
	streetNumber: '',
	apartment: '',
	building: '',
	buildingNumber: '',
};
