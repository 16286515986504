<template>
	<el-table :data="orderItems" height="400" style="width: 100%" highlight-current-row>
		<el-table-column prop="product" label="Product" sortable>
			<template #default="scope">
				<span>{{ scope.row.product.name }}</span>
			</template>
		</el-table-column>
		<el-table-column prop="quantity" label="Quantity" sortable>
			<template #default="scope">
				<span style="margin-left: 10px">{{ scope.row.quantity }}</span>
			</template>
		</el-table-column>
		<el-table-column prop="originalPrice" label="Product Price" sortable>
			<template #default="scope">
				<span style="margin-left: 10px">{{ scope.row.originalPrice.price }} {{ scope.row.originalPrice.currencyCode }}</span>
			</template>
		</el-table-column>
		<el-table-column prop="discount" label="Discount" sortable>
			<template #default="scope">
				<span style="margin-left: 10px">{{ scope.row.discount }}</span>
			</template>
		</el-table-column>
		<el-table-column prop="price" label="Price" sortable>
			<template #default="scope">
				<span style="margin-left: 10px">{{ scope.row.price.price }} {{ scope.row.price.currencyCode }}</span>
			</template>
		</el-table-column>
	</el-table>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, watch } from 'vue';
import { OrderItem } from '../models/order-item.model';

export default defineComponent({
	props: ['orderItems'],
	setup(props) {
		const state = reactive({
			orderItems: props.orderItems as Array<OrderItem>,
		});

		watch(
			() => props.orderItems,
			() => {
				state.orderItems = props.orderItems as Array<OrderItem>;
			}
		);

		return {
			...toRefs(state),
		};
	},
});
</script>

<style scoped lang="scss"></style>
