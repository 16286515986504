<template>
	<div style="overflow-y: auto; height: 55vh">
		<pdf :src="url" :page="currentPageIndex" v-if="!loading">
			<template #loading> Loading... </template>
		</pdf>
	</div>

	<div class="text-center">
		<div class="btn-group btn-group-sm mx-auto">
			<el-button @click.prevent="prevPage()"
				><el-icon size="24px"><caretleft /></el-icon
			></el-button>
			<span>{{ currentPageIndex }} / {{ totalPages }}</span>
			<el-button @click.prevent="nextPage()"
				><el-icon size="24px"><caretright /></el-icon
			></el-button>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs, watch } from 'vue';
import pdf from 'pdfvuer';

interface IPdfState {
	currentPageIndex: number;
	totalPages: number;
	pdf: any;
	loading: boolean;
}

export default defineComponent({
	props: ['width', 'height', 'url'],
	components: { pdf },
	setup(props) {
		const state = reactive<IPdfState>({
			currentPageIndex: 1,
			pdf: {},
			totalPages: 0,
			loading: false,
		});

		watch(
			() => props.url,
			async () => {
				await fetchPdf();
			}
		);

		onMounted(() => {
			if (props.url) fetchPdf();
		});

		const fetchPdf = () => {
			state.loading = true;
			state.pdf = pdf.createLoadingTask(props.url);
			state.currentPageIndex = 1;
			state.pdf.then((doc) => {
				state.totalPages = doc.numPages;
				state.loading = false;
			});
		};

		const prevPage = () => {
			state.currentPageIndex--;
			if (state.currentPageIndex < 1) {
				state.currentPageIndex = 1;
			}
		};
		const nextPage = () => {
			state.currentPageIndex++;
			if (state.currentPageIndex > state.totalPages) {
				state.currentPageIndex = state.totalPages;
			}
		};

		return {
			...toRefs(state),
			prevPage,
			nextPage,
		};
	},
});
</script>

<style scoped></style>
