import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"overflow-y":"auto","height":"55vh"} }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "btn-group btn-group-sm mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pdf = _resolveComponent("pdf")!
  const _component_caretleft = _resolveComponent("caretleft")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_caretright = _resolveComponent("caretright")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.loading)
        ? (_openBlock(), _createBlock(_component_pdf, {
            key: 0,
            src: _ctx.url,
            page: _ctx.currentPageIndex
          }, {
            loading: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Loading... ")
            ])),
            _: 1
          }, 8, ["src", "page"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_button, {
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.prevPage()), ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_icon, { size: "24px" }, {
              default: _withCtx(() => [
                _createVNode(_component_caretleft)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.currentPageIndex) + " / " + _toDisplayString(_ctx.totalPages), 1),
        _createVNode(_component_el_button, {
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.nextPage()), ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_icon, { size: "24px" }, {
              default: _withCtx(() => [
                _createVNode(_component_caretright)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}