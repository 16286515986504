import { environment } from '@/common/environment';
import { useDelete, useGet, usePatch, usePost, usePut } from '@/common/services/api.service';
import { Employee } from '../models/employee';

const baseUrl = `${environment.apiUrl}/employees`;

const getEmployees = async (): Promise<Employee[]> => {
	const result = useGet<Array<Employee>>(baseUrl);
	return result;
};

const getEmployee = async (employeeId: number) => {
	const result = useGet<Employee>(`${baseUrl}/${employeeId}`);
	return result;
};

const createEmployee = async (employee: Employee) => {
	const result = usePost(baseUrl, {
		firstName: employee.firstName,
		lastName: employee.lastName,
		phoneNumber: employee.phoneNumber,
		email: employee.email,
		username: employee.username,
		cnp: employee.cnp,
	});
	return result;
};

const updateEmployee = async (employee: Employee) => {
	const result = usePut(`${baseUrl}/${employee.id}`, {
		id: employee.id,
		firstName: employee.firstName,
		lastName: employee.lastName,
		phoneNumber: employee.phoneNumber,
		email: employee.email,
		username: employee.username,
	});
	return result;
};

const deleteEmployee = async (employeeId: number) => {
	const result = useDelete(`${baseUrl}/${employeeId}`);
	return result;
};

const addEmployeeRole = async (employeeId: number, role: string) => {
	const result = usePatch(`${baseUrl}/${employeeId}/add-role/${role}`, null);
	return result;
};

const removeEmployeeRole = async (employeeId: number, role: string) => {
	const result = usePatch(`${baseUrl}/${employeeId}/remove-role/${role}`, null);
	return result;
};

const beginEmployeeActivation = async (employeeId: number) => {
	return await usePatch(`${baseUrl}/${employeeId}/activate`, null);
};

const resetEmployee = async (employeeId: number) => {
	return await usePatch(`${baseUrl}/${employeeId}/reset`, null);
};

const confirmEmployeeEmail = async (token: string) => {
	return usePost(`${baseUrl}/confirm-account?token=${token}`, {}, { useAuth: false, useLoader: true });
};

const generatePhoneValidationSession = async (token: string) => {
	return useGet(`${baseUrl}/confirm-account/phone-number?token=${token}`, { useAuth: false });
};

const confirmPhoneNumber = async (confirmAccountDto: any) => {
	return usePost(`${baseUrl}/confirm-account/phone-number`, confirmAccountDto, { useAuth: false, useLoader: true });
};

const lockEmployee = async (employeeId: number) => {
	return await usePatch(`${baseUrl}/${employeeId}/lock`, null);
};

const unlockEmployee = async (employeeId: number) => {
	return await usePatch(`${baseUrl}/${employeeId}/unlock`, null);
};

export {
	getEmployees,
	createEmployee,
	updateEmployee,
	deleteEmployee,
	addEmployeeRole,
	removeEmployeeRole,
	confirmEmployeeEmail,
	generatePhoneValidationSession,
	confirmPhoneNumber,
	beginEmployeeActivation,
	resetEmployee,
	lockEmployee,
	unlockEmployee,
	getEmployee,
};
