<template>
	<div class="action-bar">
		<div style="display: flex; flex-direction: row; gap: 3rem;">
			<div style="display: flex; flex-direction: row;">
				<el-button circle type="success" @click="addRecord()" v-if="addNew">
					<el-icon><plus /></el-icon>
				</el-button>
				<el-button circle type="primary" @click="openRecord()" v-if="showDetails" :disabled="!selectedRow">
					<el-icon><edit /></el-icon>
				</el-button>
				<el-button circle type="danger" @click="deleteRecord()" v-if="deleteItem" :disabled="!selectedRow">
					<el-icon><delete /></el-icon>
				</el-button>
			</div>
			<div style="display: flex; flex-direction: row;">
				<slot name="actions"></slot>
			</div>
		</div>
		<div class="default-actions">
			<el-input v-model="filterValue" clearable placeholder="Search" class="search-input" v-if="!!filterFunc">
				<template #suffix>
					<i class="el-icon-search"></i>
				</template>
			</el-input>
			<el-button v-if="showRefresh" flat type="default" @click="onRefresh">
				<el-icon><refresh /></el-icon>
			</el-button>
		</div>
	</div>

	<el-table
		:data="pagedData"
		:height="height"
		:width="width"
		:default-sort="defaultSort"
		:highlight-current-row="highlightCurrentRow"
		@current-change="onRowClick"
		@sort-change="onSortChanged"
		@row-dblclick="openRecord"
	>
		<slot></slot>
	</el-table>

	<el-pagination
		layout="total, prev, pager, next, sizes"
		:total="totalItems"
		:currentPage="currentPage"
		:page-size="pageSize"
		:page-sizes="[25, 50, 100]"
		@current-change="currentPageChanged"
		@size-change="pageSizeChanged"
	/>
</template>

<script lang="ts">
import debounce from 'debounce';
import { defineComponent, reactive, toRefs, watch, onMounted } from 'vue';

export default defineComponent({
	props: ['tableData', 'filterFunc', 'height', 'width', 'defaultSort', 'highlightCurrentRow', 'showRefresh', 'addNew', 'showDetails', 'deleteItem', 'initialState'],
	emits: ['onRefresh', 'rowSelected', 'onStateChange'],
	setup(props, { emit }) {
		const state = reactive({
			initialData: new Array<any>(),
			filteredData: new Array<any>(),
			filterValue: '',
			showRefresh: false,
			selectedRow: null,
			sortBy: null,
			sortOrder: null,
		});
		const pagerState = reactive({
			pagedData: new Array<any>(),
			currentPage: 1,
			pageSize: 25,
			totalItems: 0,
		});

		watch(
			() => props.tableData,
			() => {
				state.initialData = props.tableData;
				pagerState.totalItems = props.tableData.length;
				filterData();
			}
		);

		watch(
			() => props.showRefresh,
			() => {
				state.showRefresh = props.showRefresh;
			}
		);

		watch(
			() => state.filterValue,
			() => {
				filterData();
				emit('onStateChange', state)
			}
		);

		watch(
			() => props.initialState,
			() => {
				state.filterValue = props.initialState?.filterValue || '';
				state.sortBy = props.initialState?.sortBy || null;
				state.sortOrder = props.initialState?.sortOrder || null;
				pagerState.pageSize = parseInt(props.initialState?.pageSize || 25);
				pagerState.currentPage = parseInt(props.initialState?.currentPage || 1);
			}
		)

		onMounted(() => {
			state.initialData = props.tableData;
			state.showRefresh = props.showRefresh;
			state.filterValue = props.initialState?.filterValue || '';
			state.sortBy = props.initialState?.sortBy || null;
			state.sortOrder = props.initialState?.sortOrder || null;
			pagerState.pageSize = parseInt(props.initialState?.pageSize || 25);
			pagerState.currentPage = parseInt(props.initialState?.currentPage || 1);
			console.log('showRefresh', state.showRefresh);
			filterData();
		});

		const pageSizeChanged = (val: number) => {
			pagerState.pageSize = val;
			pageChanged();
		};

		const currentPageChanged = (val: number) => {
			pagerState.currentPage = val;
			pageChanged();
		};

		const filterData = debounce(() => {
			if (props.filterFunc) {
				state.filteredData = state.initialData.filter((f) => {
					return props.filterFunc(f, state.filterValue);
				});
				pagerState.totalItems = state.filteredData.length;
				pageChanged();
			} else {
				state.filteredData = state.initialData;
				pagerState.totalItems = state.filteredData.length;
				pageChanged();
			}
		}, 300);

		const pageChanged = () => {
			pagerState.pagedData = state.filteredData.slice(
				(pagerState.currentPage - 1) * pagerState.pageSize,
				(pagerState.currentPage - 1) * pagerState.pageSize + pagerState.pageSize
			);
			emitStateChanged();
		};

		const onSortChanged = ({ column, prop, order }) => {
			pagerState.currentPage = 1;
			state.filteredData = state.filteredData.sort(function (first, second) {
				let sortBy = prop;
				if (!prop && !!column) sortBy = column.sortBy;
				else if (!column) return 0;

				let firstProp = getValue(first, sortBy);
				let secondProp = getValue(second, sortBy);

				const compResult = firstProp > secondProp ? 1 : firstProp < secondProp ? -1 : 0;
				return order === 'ascending' ? compResult : -compResult;

				function getValue(obj, prop: string) {
					if (!obj) return null;
					if (prop.indexOf('.') > -1) {
						const propPath = prop.split('.');
						if (obj[propPath[0]]) return getValue(obj[propPath[0]], propPath.slice(1).join('.'));
						else return null;
					}

					if (typeof obj[prop] === 'string') return obj[prop].toLowerCase();

					return obj[prop];
				}
			});

			pageChanged();
		};

		const onRowClick = (row) => {
			state.selectedRow = row;
			emit('rowSelected', row);
		};

		const addRecord = () => {
			if (props.addNew) props.addNew();
		}

		const openRecord = () => {
			if(props.showDetails) props.showDetails(state.selectedRow);
		}

		const deleteRecord = () => {
			if (props.deleteItem) props.deleteItem(state.selectedRow);
		}

		const onRefresh = () => {
			state.filterValue = '';
			emit('onRefresh');
		};

		const emitStateChanged = () => {
			const gridState = {
				filterValue: state.filterValue,
				currentPage: pagerState.currentPage,
				pageSize: pagerState.pageSize,
				sortBy: state.sortBy,
				sortOrder: state.sortOrder,
			}
			emit('onStateChange', gridState);
		}

		return {
			...toRefs(state),
			...toRefs(pagerState),
			pageSizeChanged,
			currentPageChanged,
			onSortChanged,
			onRowClick,
			onRefresh,
			addRecord,
			openRecord,
			deleteRecord,
		};
	},
});
</script>

<style lang="scss" scoped>
.action-bar {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 1rem 0;
	> .search-input {
		max-width: 220px;
	}
	> .default-actions {
		display: flex;
		flex-direction: row;
		gap: 1rem;
	}
}
</style>
