<template>
	<div class="doc-panel">
		<div class="doc-viewer">
			<template v-if="selectedDocument">
				<div class="doc-header">
					<h3>{{ selectedDocument.fileName }}</h3>
					<el-button icon type="success" @click="downloadFile(selectedDocument)">
						<el-icon><download /></el-icon>
					</el-button>
				</div>
				<pdf-viewer
					v-if="selectedDocument.fileName.endsWith('.pdf')"
					:url="getPdfFromBlob(selectedDocument.file)"
					height="55vh"
					width="100%"
				></pdf-viewer>
				<img v-else-if="isImage(selectedDocument)" :src="getImage(selectedDocument.file)" />
				<video v-else-if="isVideo(selectedDocument)" :src="getImage(selectedDocument.file)" controls></video>
			</template>
		</div>
		<div class="doc-list">
			<div v-for="(item, idx) in data" :key="idx">
				<div class="file-button" @click="selectFile(item)">
					<el-icon :size="32">
						<picturefilled
							v-if="
								item.documentType === ClientDocumentType.IdentityDocument ||
								item.documentType === ClientDocumentType.BiometricValidationDocument
							"
						/>
						<document v-else />
					</el-icon>
					<span>{{ item.documentName }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, watch, toRefs } from 'vue';
import PdfViewer from '@/components/pdf-viewer/PdfViewer.vue';
import { ClientDocument, ClientDocumentType } from '../models/client-document.model';
import * as clientSvc from '../services/client.service';
import { saveAs } from 'file-saver';

interface IClientDocumentState {
	data: Array<ClientDocument>;
	documentViewerVisible: boolean;
	documents: Array<{ file: Blob | null; fileName: string; fileType: ClientDocumentType }>;
	selectedDocument: { file: Blob | null; fileName: string; fileType: ClientDocumentType } | null;
}

export default defineComponent({
	props: ['clientId'],
	components: { PdfViewer },
	setup(props) {
		const state = reactive<IClientDocumentState>({
			data: new Array<ClientDocument>(),
			documentViewerVisible: false,
			documents: new Array<{ file: Blob | null; fileName: string; fileType: ClientDocumentType }>(),
			selectedDocument: null,
		});

		onMounted(async () => {
			await loadClientDocuments();
			if (state.documents.length > 0) state.selectedDocument = state.documents[0];
		});

		const isImage = (document: { file: Blob | null; fileName: string; fileType: ClientDocumentType }) => {
			return (
				document != null &&
				(document.fileName.endsWith('.jpg') || document.fileName.endsWith('.jpeg') || document.fileName.endsWith('.png'))
			);
		};
		const isVideo = (document: { file: Blob | null; fileName: string; fileType: ClientDocumentType }) => {
			return document != null && document.fileName.endsWith('.webm');
		};

		watch(
			() => props.clientId,
			async () => {
				await loadClientDocuments();
			}
		);

		const loadClientDocuments = async () => {
			if (props.clientId) {
				state.data = await clientSvc.getClientDocuments(props.clientId);
			}
		};

		const getDocuments = async () => {
			const promises = state.data.map((doc) => {
				try {
					return clientSvc.getClientDocument(props.clientId, doc.id!).then((item) => {
						return { ...item, fileType: doc.documentType };
					});
				} catch {
					return Promise.resolve({ file: null, fileName: doc.documentName });
				}
			});

			state.documents = (await Promise.allSettled(promises)).filter((f) => f.status === 'fulfilled').map((m: any) => m.value);
		};

		const getImage = (file: Blob | null) => {
			if (file) return URL.createObjectURL(file);
		};

		const selectFile = async (file) => {
			if (state.documents.some((s) => s.fileType === file.fileType && s.fileName === file.fileName)) {
				state.selectedDocument = state.documents.find((s) => s.fileType === file.fileType && s.fileName === file.fileName) ?? null;
			} else {
				var doc = await clientSvc.getClientDocument(props.clientId, file.id!);
				state.documents.push({ ...doc, fileType: file.fileType, fileName: file.documentName });
				state.selectedDocument = { ...doc, fileType: file.documentType, fileName: file.documentName };
			}
		};

		const getPdfFromBlob = (pdf: Blob | null) => {
			if (pdf != null) return URL.createObjectURL(pdf);
		};

		const downloadFile = (file) => {
			if (state.selectedDocument) saveAs(file.file, state.selectedDocument.fileName);
		};

		return {
			...toRefs(state),
			ClientDocumentType,
			getImage,
			isImage,
			isVideo,
			selectFile,
			getPdfFromBlob,
			downloadFile,
		};
	},
});
</script>

<style scoped lang="scss">
.doc-panel {
	display: flex;
	flex-direction: column;
	height: 100%;

	.doc-viewer {
		flex: 1 1 60vh;

		> * {
			max-height: 55vh;
		}

		.doc-header {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: 1rem;
		}
	}

	.doc-list {
		flex: 0 0 10%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		gap: 1rem;
		overflow-x: auto;

		.file-button {
			display: flex;
			flex-direction: column;
			font-size: 0.7rem;
			max-width: 64px;
			align-items: center;

			&:hover {
				cursor: pointer;
			}

			> span {
				word-break: break-word;
			}
		}
	}
}
</style>
