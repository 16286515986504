<template>
	<el-table :data="orderDocuments" height="400" style="width: 100%" highlight-current-row>
		<el-table-column prop="documentName" label="Name" sortable></el-table-column>
		<el-table-column prop="documentType" label="Type" sortable>
			<template #default="scope">
				<span>{{ ClientDocumentType[scope.row.documentType] }}</span>
			</template>
		</el-table-column>
		<el-table-column prop="createdOn" label="Created On" sortable>
			<template #default="scope">
				<span style="margin-left: 10px">{{ formatDate(scope.row.createdOn) }}</span>
			</template>
		</el-table-column>
	</el-table>
</template>

<script lang="ts">
import { reactive, watch } from 'vue';
import { toRefs } from 'vue';
import { formatDate } from '@/common/component-utils';
import { ClientDocument, ClientDocumentType } from '@/features/clients/models/client-document.model';

export default {
	props: ['orderDocuments'],
	setup(props) {
		const state = reactive({
			orderDocuments: props.orderDocuments as Array<ClientDocument>,
		});

		watch(
			() => props.orderDocuments,
			() => {
				state.orderDocuments = props.orderDocuments as Array<ClientDocument>;
			}
		);

		return {
			...toRefs(state),
			formatDate,
			ClientDocumentType,
		};
	},
};
</script>

<style lang="scss" scoped></style>
