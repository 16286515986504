import { environment } from '@/common/environment';
import { getUser } from '@/common/services/user.service';
import axios from 'axios';
import { Client } from '../models/client.model';
import { Person } from '@/common/models/person.model';
import { usePatch, usePost, usePut, useGet, useDelete, downloadFile } from '@/common/services/api.service';
import { Product } from '@/features/admin/products/models/product.model';
import { Address } from '@/common/models/address.model';
import { ClientDocument } from '../models/client-document.model';
import { UserValidationSession } from '../models/user-validation-session.model';
import { ClientStatsState } from '../models/client-stats.state';
import { ClientsStatsState } from '../models/clients-stats.state';
import { ValidationSessionLog } from '../models/validation-session-log.model';

const baseUrl = `${environment.apiUrl}/clients`;
const personsUrl = `${environment.apiUrl}/persons`;
const sessionsUrl = `${environment.apiUrl}/validation-sessions`;
const videoSessionUrl = `${environment.apiUrl}/video-session`;

const getClients = async (): Promise<Client[]> => {
	const user = getUser();
	const response = await useGet<Array<Client>>(baseUrl);
	return response;
};

const getClient = async (id: number): Promise<Client> => {
	const user = getUser();
	const response = await axios.get(`${baseUrl}/${id}`, {
		headers: {
			Authorization: `Bearer ${user.JWT}`,
		},
	});
	const data = response.data as Client;
	return data;
};

const createClient = async (client: Person): Promise<Client> => {
	const result = await usePost<Client>(baseUrl, { ...client });
	return result;
};

const updateClient = async (client: Person) => {
	const result = await usePut(`${baseUrl}/${client.id}/person`, client);
	return result;
};

const resetClientUser = async (clientId: number, newUsername: string, newPhoneNumber: string) => {
	const result = await usePatch(`${baseUrl}/${clientId}/reset`, {
		newUsername,
		newPhoneNumber,
	});
	return result;
};

const getPerson = async (personId: number): Promise<Person> => {
	const result = await useGet<Person>(`${personsUrl}/${personId}`);
	return result;
};

const uploadIdCard = async (personId: number, file: Blob) => {
	const formData = new FormData();
	formData.append('idCard', file);
	await usePut(`${personsUrl}/${personId}/id`, formData);
};

const createOrder = async (
	clientId: number,
	invoiceAddress: Address | null,
	deliveryAddress: Address | null,
	deliveryMethod: number,
	email: string,
	phoneNumber: string,
	product: Product,
	paymentMethod: number,
	bypassVideoValidation: boolean,
	agreedToBiometricValidation: boolean
): Promise<void> => {
	await usePatch(`${baseUrl}/${clientId}/create-certificate`, {
		product,
		paymentMethod,
		bypassVideoValidation,
		agreedToBiometricValidation,
		invoiceAddress,
		deliveryAddress,
		deliveryMethod,
		email,
		phoneNumber,
	});
};

const getMRZData = async (file: Blob) => {
	const formData = new FormData();
	formData.append('idCard', file);
	return await usePost(`${baseUrl}/create/mrz`, formData);
};

const deleteClient = async (clientId: number) => {
	return await useDelete(`${baseUrl}/${clientId}`);
};

async function getClientValidationSessions(clientId: number) {
	const response = await useGet<Array<UserValidationSession>>(`${baseUrl}/${clientId}/validation-sessions`);
	return response;
}

async function getClientDocuments(clientId: number) {
	const response = await useGet<Array<ClientDocument>>(`${baseUrl}/${clientId}/documents`);
	return response;
}

async function getClientDocument(clientId: number, documentId: number) {
	const response = await downloadFile(`${baseUrl}/${clientId}/documents/${documentId}`);
	return response;
}

async function getVideoSessionDocument(videoSessionId: number, userValidationId: number, documentId: number) {
	const response = await downloadFile(
		`${videoSessionUrl}/${videoSessionId}/validation-session/${userValidationId}/documents/${documentId}`
	);
	return response;
}

async function getClientStats(clientId: number) {
	const response = await useGet<ClientStatsState>(`${baseUrl}/${clientId}/stats`);
	return response;
}

async function getAllClientsStats(startDate: Date | null, endDate: Date | null): Promise<ClientsStatsState> {
	const response = await useGet<ClientsStatsState>(`${baseUrl}/clients-summary`, { params: { startDate: startDate, endDate: endDate } });
	return response;
}

async function updateValidationStep(clientId: number, sessionId: number, stepId, resolution: boolean, comments: string): Promise<void> {
	const response = await usePut<void>(`${baseUrl}/${clientId}/validation-sessions/${sessionId}/steps/${stepId}`, {
		approved: resolution,
		comment: comments,
	});
	return response;
}

async function resendStep(clientId: number, sessionId: number, stepId: number): Promise<void> {
	await usePatch<void>(`${baseUrl}/${clientId}/validation-sessions/${sessionId}/steps/${stepId}/resend`, {});
}

async function getValidationSessionLogs(clientId: number, sessionId: number): Promise<Array<ValidationSessionLog> | null> {
	const response = await useGet<Array<ValidationSessionLog>>(`${baseUrl}/${clientId}/validation-sessions/${sessionId}/logs`);
	return response;
}

async function lockClientCertificate(personId: number, certificateId: number): Promise<void> {
	await useDelete(`${baseUrl}/${personId}/certificates/${certificateId}`);
}

async function changeUserValidationProcess(sessionId: number): Promise<void> {
	await usePatch(`${sessionsUrl}/${sessionId}/change-process`, {});
}

async function syncClientCertificates(clientId: number): Promise<void> {
	await usePatch(`${baseUrl}/${clientId}/certificates/sync`, {});
}

export {
	getClients,
	getClient,
	getPerson,
	createClient,
	updateClient,
	resetClientUser,
	createOrder,
	uploadIdCard,
	getMRZData,
	deleteClient,
	getClientValidationSessions,
	getClientDocuments,
	getClientDocument,
	getVideoSessionDocument,
	getClientStats,
	getAllClientsStats,
	updateValidationStep,
	resendStep,
	getValidationSessionLogs,
	lockClientCertificate,
	changeUserValidationProcess,
	syncClientCertificates,
};
