import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "widget-container" }
const _hoisted_2 = { class: "widget" }
const _hoisted_3 = { class: "widget" }
const _hoisted_4 = { class: "widget" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_certificate_request_list = _resolveComponent("certificate-request-list")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_card, { "body-style": { height: 'calc(50vh - 55px)', 'overflow-y': 'auto' } }, {
        header: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "card-header" }, [
            _createElementVNode("span", null, "Pending Approval (Clients)")
          ], -1)
        ])),
        default: _withCtx(() => [
          _createVNode(_component_certificate_request_list, {
            clients: "",
            status: _ctx.CertificateRequestStatus.PendingApproval.key,
            height: "300px"
          }, null, 8, ["status"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_card, { "body-style": { height: 'calc(50vh - 55px)', 'overflow-y': 'auto' } }, {
        header: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("div", { class: "card-header" }, [
            _createElementVNode("span", null, "Pending Approval (Signatories)")
          ], -1)
        ])),
        default: _withCtx(() => [
          _createVNode(_component_certificate_request_list, {
            companies: "",
            status: _ctx.CertificateRequestStatus.PendingApproval.key,
            height: "300px"
          }, null, 8, ["status"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_el_card, { "body-style": { height: 'calc(50vh - 55px)', 'overflow-y': 'auto' } }, {
        header: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("div", { class: "card-header" }, [
            _createElementVNode("span", null, "Pending Validation (Signatories)")
          ], -1)
        ])),
        default: _withCtx(() => [
          _createVNode(_component_certificate_request_list, {
            companies: "",
            status: _ctx.CertificateRequestStatus.PendingValidation.key,
            height: "300px"
          }, null, 8, ["status"])
        ]),
        _: 1
      })
    ])
  ]))
}