import { Address } from '@/common/models/address.model';
import { BaseEntity } from '@/common/models/base.model';
import { Currency } from '@/common/models/currency.model';
import { Client, emptyClient } from '@/features/clients/models/client.model';
import { UserValidationSession } from '@/features/clients/models/user-validation-session.model';
import { ClientDocument } from '../../clients/models/client-document.model';
import { OrderItem } from './order-item.model';
import { CertificateRequest } from '@/features/companies/models/certificate-request.model';

export interface Order extends BaseEntity {
	orderItems: Array<OrderItem>;
	totalValue: Currency;
	orderDocuments: Array<ClientDocument>;
	orderStatus: OrderStatus;
	discount: number;
	finalValue: Currency;
	paymentMethod: PaymentMethod | null;
	paymentStatus: PaymentStatus | null;
	useInvoiceAddress: boolean;
	invoiceAddress: Address | null;
	deliveryAddress: Address | null;
	deliveryMethod: DeliveryMethod | null;
	client: Client;
	orderName: string;
	orderSession: UserValidationSession | null;
	email: string;
	phoneNumber: string;
	personType: PersonType;
	companyName: string | null;
	cui: string | null;
	onrcNumber: string | null;
	isTaxPayer: boolean | null;
	certificateRequestId: number | null;
	certificateRequest: CertificateRequest | null;
}

export enum DeliveryMethod {
	Delivery = 1,
	Pickup = 2,
	Online = 3,
}

export enum OrderStatus {
	Placed = 0,
	ClientValidation = 1,
	Payment = 2,
	PendingFullfillment = 3,
	Fulfilled = 4,
	Cancelled = 5,
	PendingApproval = 6,
}

export enum PaymentStatus {
	Pending = 1,
	PartiallyPaid = 2,
	Paid = 3,
	Cancelled = 4,
}

export enum PaymentMethod {
	Online = 1,
	BankTransfer = 2,
	Cash = 4,
}

export enum PersonType {
	NaturalPerson = 1,
	LegalPerson = 2,
}

export const emptyOrder: Order = {
	id: 0,
	createdBy: null,
	createdOn: null,
	modifiedBy: null,
	modifiedOn: null,
	isDeleted: false,
	orderName: '',
	client: emptyClient,
	orderItems: [],
	orderDocuments: [],
	orderStatus: 1,
	totalValue: {
		currencyCode: '',
		price: 0,
	},
	finalValue: {
		currencyCode: '',
		price: 0,
	},
	discount: 0,
	paymentMethod: null,
	paymentStatus: null,
	useInvoiceAddress: false,
	invoiceAddress: null,
	orderSession: null,
	deliveryAddress: null,
	deliveryMethod: null,
	email: '',
	phoneNumber: '',
	personType: PersonType.NaturalPerson,
	companyName: null,
	cui: null,
	onrcNumber: null,
	isTaxPayer: null,
	certificateRequestId: null,
	certificateRequest: null,
};
