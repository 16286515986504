import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_check = _resolveComponent("check")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_close = _resolveComponent("close")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_data_grid = _resolveComponent("data-grid")!
  const _component_certificate_request_details = _resolveComponent("certificate-request-details")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_data_grid, {
      "table-data": _ctx.dataSource,
      "filter-func": _ctx.filterData,
      "default-sort": { prop: 'createdOn', order: 'descending' },
      height: _ctx.height,
      "highlight-current-row": "",
      "show-details": _ctx.showDetails,
      "delete-item": _ctx.deleteItem,
      ref: "table",
      "show-refresh": true,
      onOnRefresh: _ctx.refresh
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_el_button, {
          round: "",
          type: "success",
          onClick: _ctx.approveCertificateRequests,
          disabled: !_ctx.allowApprovalActions
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_icon, { size: 20 }, {
              default: _withCtx(() => [
                _createVNode(_component_check)
              ]),
              _: 1
            }),
            _createTextVNode(_toDisplayString(_ctx.i18n.getLocalised('certificateRequests.actions.approve')), 1)
          ]),
          _: 1
        }, 8, ["onClick", "disabled"]),
        _createVNode(_component_el_button, {
          round: "",
          type: "danger",
          onClick: _ctx.rejectCertificateRequests,
          disabled: !_ctx.allowApprovalActions
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_icon, { size: 20 }, {
              default: _withCtx(() => [
                _createVNode(_component_close)
              ]),
              _: 1
            }),
            _createTextVNode(_toDisplayString(_ctx.i18n.getLocalised('certificateRequests.actions.reject')), 1)
          ]),
          _: 1
        }, 8, ["onClick", "disabled"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "person.firstName",
          label: _ctx.i18n.getLocalised('certificateRequests.fields.firstName'),
          sortable: "custom"
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "person.lastName",
          label: _ctx.i18n.getLocalised('certificateRequests.fields.lastName'),
          sortable: "custom"
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "email",
          label: _ctx.i18n.getLocalised('certificateRequests.fields.email')
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "phoneNumber",
          label: _ctx.i18n.getLocalised('certificateRequests.fields.phoneNumber')
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "person.cnp",
          label: _ctx.i18n.getLocalised('certificateRequests.fields.cnp'),
          sortable: "custom"
        }, null, 8, ["label"]),
        (!!_ctx.companies)
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 0,
              prop: "company.companyName",
              label: _ctx.i18n.getLocalised('certificateRequests.fields.companyName'),
              sortable: "custom"
            }, null, 8, ["label"]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_table_column, {
          prop: "requestStatus.value",
          label: _ctx.i18n.getLocalised('certificateRequests.fields.requestStatus'),
          sortable: "custom"
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "createdOn",
          label: _ctx.i18n.getLocalised('certificateRequests.fields.createdOn'),
          sortable: "custom"
        }, null, 8, ["label"])
      ]),
      _: 1
    }, 8, ["table-data", "filter-func", "height", "show-details", "delete-item", "onOnRefresh"]),
    _createVNode(_component_el_dialog, {
      title: "Certificate Request Details",
      modelValue: _ctx.showDetailsDialog,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDetailsDialog) = $event)),
      top: "2vh",
      width: "75%",
      "destroy-on-close": "",
      "close-on-click-modal": false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_certificate_request_details, {
          model: _ctx.detailsForRecord,
          onOnStatusChange: _ctx.handleStatusChange
        }, null, 8, ["model", "onOnStatusChange"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}