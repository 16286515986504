import { environment } from '@/common/environment';
import { useDelete, useGet, usePatch } from '@/common/services/api.service';
import { CertificateRequest } from '../models/certificate-request.model';
import { ValidationSessionLog } from '@/features/clients/models/validation-session-log.model';

const baseUrl = `${environment.apiUrl}/certificate-requests`;

export const getCertificateRequests = async (
	companyId: number | null = null,
	clientsOnly: boolean | null = null,
	companiesOnly: boolean | null = null,
	status: number | null = null
): Promise<Array<CertificateRequest>> => {
	const params = new Array<string>();
	if (companyId) {
		params.push(`companyId=${companyId}`);
	}
	if (clientsOnly) {
		params.push(`clients=${clientsOnly}`);
	}
	if (companiesOnly) {
		params.push(`companies=${companiesOnly}`);
	}
	if (status) {
		params.push(`status=${status}`);
	}
	const url = `${baseUrl}?${params.join('&')}`;
	return await useGet<Array<CertificateRequest>>(url);
};

export const setApprovalStatus = async (certificateRequestId: number, status: boolean) => {
	return await usePatch(`${baseUrl}/${certificateRequestId}/set-approval-status`, { approvalStatus: status });
};

export const getCertificateRequest = async (certificateRequestId: number) => {
	return await useGet<CertificateRequest>(`${baseUrl}/${certificateRequestId}`);
};

export const getValidationSessionLogs = async (certificateRequestId: number) => {
	return await useGet<Array<ValidationSessionLog>>(`${baseUrl}/${certificateRequestId}/validation-session-logs`);
};

export const markAsFulfilled = async (certificateRequestId: number) => {
	return await usePatch(`${baseUrl}/${certificateRequestId}/finish-setup`, {});
};

export const resendValidationStep = async (certificateRequestId: number, sessionId: number, stepId: number) => {
	return await usePatch(`${baseUrl}/${certificateRequestId}/validation-session/${sessionId}/steps/${stepId}/resend`, {});
};

export const deleteCertificateRequest = async (certificateRequestId: number) => {
	return await useDelete(`${baseUrl}/${certificateRequestId}`);
};

export const updateValidationStep = async (
	certificateRequestId: number,
	sessionId: number,
	stepId: number,
	resolution: boolean,
	comments: string
) => {
	return await usePatch(`${baseUrl}/${certificateRequestId}/validation-session/${sessionId}/steps/${stepId}/set-status`, {
		approved: resolution,
		comment: comments,
	});
};
