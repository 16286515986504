import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "actions" }
const _hoisted_2 = { class: "actions" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "actions space-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_order_info = _resolveComponent("order-info")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_order_item_list = _resolveComponent("order-item-list")!
  const _component_order_documents = _resolveComponent("order-documents")!
  const _component_icon_switch = _resolveComponent("icon-switch")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_client_info = _resolveComponent("client-info")!
  const _component_client_documents = _resolveComponent("client-documents")!
  const _component_validation_session_edit = _resolveComponent("validation-session-edit")!
  const _component_certificate_request_details = _resolveComponent("certificate-request-details")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_close = _resolveComponent("close")!
  const _component_check = _resolveComponent("check")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_button_group, null, {
        default: _withCtx(() => [
          (_ctx.order.orderStatus === _ctx.OrderStatus.Payment && _ctx.order.paymentStatus !== _ctx.PaymentStatus.Paid)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                type: "primary",
                size: "small",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeStatusDialogVisible = true))
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("Change Payment Status")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.order.orderStatus === _ctx.OrderStatus.PendingFullfillment)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 1,
                type: "primary",
                size: "small",
                onClick: _ctx.markAsFulfilled
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("Mark as fulfilled")
                ])),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.order.orderStatus === _ctx.OrderStatus.PendingFullfillment || _ctx.order.orderStatus === _ctx.OrderStatus.Fulfilled)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 2,
                type: "primary",
                size: "small",
                onClick: _ctx.sendToFulfillment
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("Send order to fulfillment")
                ])),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.order.orderStatus === _ctx.OrderStatus.PendingFullfillment)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 3,
                type: "primary",
                size: "small",
                onClick: _ctx.resendCertificateEmail
              }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("Resend Certiticate Email")
                ])),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeTab) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: "Order Info",
          name: "orderInfo"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_order_info, { "order-info": _ctx.order }, null, 8, ["order-info"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "Order Items",
          name: "orderItems"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_order_item_list, {
              "order-items": _ctx.order.orderItems
            }, null, 8, ["order-items"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "Order Documents",
          name: "orderDocuments"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_order_documents, {
              "order-documents": _ctx.order.orderDocuments
            }, null, 8, ["order-documents"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "Client Info",
          name: "clientInfo"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_button, {
                link: "",
                type: "primary",
                size: "small",
                onClick: _ctx.openClient
              }, {
                default: _withCtx(() => [
                  _cache[11] || (_cache[11] = _createTextVNode("Open Client")),
                  _createVNode(_component_el_icon, { style: {"margin-left":"0.3rem"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_icon_switch)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _createVNode(_component_client_info, {
              tabPosition: 'top',
              model: _ctx.order.client.person,
              "read-only": true
            }, null, 8, ["model"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "Client Documents",
          name: "clientDocuments"
        }, {
          default: _withCtx(() => [
            (_ctx.order.client?.id)
              ? (_openBlock(), _createBlock(_component_client_documents, {
                  key: 0,
                  "client-id": _ctx.order.client.id
                }, null, 8, ["client-id"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "Validation Session",
          name: "validationSession"
        }, {
          default: _withCtx(() => [
            (!!_ctx.order.orderSession)
              ? (_openBlock(), _createBlock(_component_validation_session_edit, {
                  key: 0,
                  session: _ctx.order.orderSession,
                  "session-log": _ctx.validationSessionLog,
                  "client-id": _ctx.order.client.id!,
                  onOnValdiationStatusChange: _ctx.reloadOrder
                }, null, 8, ["session", "session-log", "client-id", "onOnValdiationStatusChange"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, "No validation session"))
          ]),
          _: 1
        }),
        (!!_ctx.order.certificateRequestId)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 0,
              label: "Certificate Request",
              name: "certificateRequest"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_certificate_request_details, {
                  model: _ctx.order.certificateRequest
                }, null, 8, ["model"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      title: "Change Status",
      width: "500px",
      modelValue: _ctx.changeStatusDialogVisible,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.changeStatusDialogVisible) = $event)),
      "append-to-body": true,
      "close-on-click-modal": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_el_form, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, { label: "New status" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.paymentStatus,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.paymentStatus) = $event)),
                    placeholder: "Select status"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_option, {
                        label: "Pending Payment",
                        value: 1
                      }),
                      _createVNode(_component_el_option, {
                        label: "Partially Paid",
                        value: 2
                      }),
                      _createVNode(_component_el_option, {
                        label: "Paid",
                        value: 3
                      }),
                      _createVNode(_component_el_option, {
                        label: "Cancelled",
                        value: 4
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, { label: "Comments" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    type: "textarea",
                    modelValue: _ctx.paymentNote,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.paymentNote) = $event)),
                    placeholder: "Payment Note"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_switch, {
                    modelValue: _ctx.generateInvoice,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.generateInvoice) = $event)),
                    "active-color": "#13ce66",
                    "inactive-color": "#ff4949",
                    "active-text": "Generate invoice"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              (!_ctx.generateInvoice)
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 0,
                    label: "Invoice Number"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: _ctx.invoiceNumber,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.invoiceNumber) = $event)),
                        placeholder: "Invoice Number"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_button, {
            round: "",
            type: "default",
            onClick: _ctx.cancelChangeStatus
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_close)
                ]),
                _: 1
              }),
              _cache[12] || (_cache[12] = _createTextVNode(" Cancel"))
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            round: "",
            type: "primary",
            onClick: _ctx.submitForm
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_check)
                ]),
                _: 1
              }),
              _cache[13] || (_cache[13] = _createTextVNode("Confirm"))
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}