import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "color-switcher" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_switch, {
        modelValue: _ctx.isDark,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isDark) = $event)),
        "inline-prompt": "",
        "active-icon": _ctx.Sunny,
        "inactive-icon": _ctx.MoonNight
      }, null, 8, ["modelValue", "active-icon", "inactive-icon"])
    ]),
    _createVNode(_component_router_view)
  ]))
}