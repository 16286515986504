import axios from 'axios';

let currentCulture: any = {};

const dictionary = new Array<{ culture: string; dictionary: Array<any> }>();

async function init(...cultures) {
	const loadingPromises = new Array<any>();
	for (let i = 0; i < cultures.length; i++) {
		loadingPromises.push(axios.get(`/i18n/${cultures[i]}.json`).then(({ data }) => data));
	}

	await Promise.all(loadingPromises).then((result) => {
		for (let i = 0; i < cultures.length; i++) {
			if (result[i]) {
				dictionary.push({ culture: cultures[i], dictionary: result[i] });
			}
		}

		currentCulture = dictionary[0];
		if (localStorage.getItem('culture')) {
			currentCulture = dictionary.find((f) => f.culture === localStorage.getItem('culture'));
		}
	});
}

function getCurrentCulture() {
	return localStorage.getItem('culture');
}

function setCulture(cultureCode) {
	currentCulture = dictionary.find((f) => f.culture === cultureCode);
	localStorage.setItem('culture', cultureCode);
}

function getLocalised(code: string, data?: any) {
	let path = [code];
	if (code.indexOf('.') >= 0) path = code.split('.');

	let currentPathProp = currentCulture.dictionary;

	for (let i = 0; i < path.length - 1; i++) {
		currentPathProp = currentPathProp[path[i]];
	}
	let result = currentPathProp[path[path.length - 1]];
	if (result) {
		if (data) {
			for (const key in Object.keys(data)) {
				const value = data[key];
				result = result.replaceAll(key, value);
			}
		}
		return result;
	}
	return code;
}

export default {
	init,
	setCulture,
	getLocalised,
	getCurrentCulture,
};
