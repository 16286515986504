import { emptyBaseEntity, BaseEntity } from './base.model';

export interface ContactMethod extends BaseEntity {
	type: ContactMethodType | null;
	value: string;
	isPreffered: boolean;
}

export const emptyContactMethod: ContactMethod = {
	...emptyBaseEntity,
	type: null,
	value: '',
	isPreffered: false,
};

export enum ContactMethodType {
	Phone = 1,
	Email = 2,
}
