import 'vue-router';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Login from '../views/Login.vue';
import ConfirmAccount from '../views/ConfirmAccount.vue';
import OrderList from '../features/orders/OrderList.vue';
import OrderEdit from '../features/orders/OrderEdit.vue';
import DashboardView from '../views/DashboardView.vue';
import UnauthorizedView from '../views/UnauthorizedView.vue';
import { getUser, UserRole } from '@/common/services/user.service';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'Dashboard',
		component: () => import(/* webpackChunkName: "main" */ '../views/Home.vue'),
		children: [
			{
				path: '',
				redirect() {
					return '/dashboard';
				},
			},
			{
				path: 'dashboard',
				name: 'DashboardWidgets',
				component: DashboardView,
				meta: { title: 'Dashboard', allowedRoles: [UserRole.BackofficeEmployee, UserRole.Administrator] },
			},
			{
				path: '/clients',
				name: 'ClientList',
				component: () => import(/* webpackChunkName: "main"*/ '../features/clients/components/ClientList.vue'),
				meta: { title: 'Clients', allowedRoles: [UserRole.BackofficeEmployee, UserRole.Administrator] },
			},
			{
				path: 'clients/:id',
				name: 'ClientDetails',
				component: () => import(/* webpackChunkName: "main"*/ '../features/clients/ClientDetails.vue'),
				props: true,
				meta: { title: 'Client', allowedRoles: [UserRole.BackofficeEmployee, UserRole.Administrator] },
			},
			{
				path: '/orders',
				name: 'Orders',
				component: OrderList,
				meta: { title: 'Orders', allowedRoles: [UserRole.BackofficeEmployee, UserRole.Administrator] },
			},
			{
				path: '/orders/:id',
				name: 'OrderEdit',
				component: OrderEdit,
				props: true,
				meta: { title: 'Order', allowedRoles: [UserRole.BackofficeEmployee, UserRole.Administrator] },
			},
			{
				path: '/companies',
				name: 'CompanyList',
				component: () => import(/* webpackChunkName: "companies" */ '../features/companies/components/CompanyList.vue'),
				meta: { title: 'Companies', allowedRoles: [UserRole.BackofficeEmployee, UserRole.Administrator] },
			},
			{
				path: 'companies/:id',
				name: 'CompanyDetails',
				component: () => import(/* webpackChunkName: "companies"*/ '../features/companies/components/CompanyDetails.vue'),
				props: true,
				meta: { title: 'Company', allowedRoles: [UserRole.BackofficeEmployee, UserRole.Administrator] },
			},
			{
				path: '/admin',
				name: 'Admin',
				component: () => import(/* webpackChunkName: "admin" */ '../features/admin/Admin.vue'),
				meta: { allowedRoles: [UserRole.Administrator] },
				children: [
					{
						name: 'ProductList',
						path: 'products',
						component: () => import(/* webpackChunkName: "admin" */ '../features/admin/products/Products.vue'),
						meta: { title: 'Products', allowedRoles: [UserRole.Administrator] },
					},
					{
						name: 'CreateProduct',
						path: 'products/new',
						component: () => import(/* webpackChunkName: "admin" */ '../features/admin/products/EditProduct.vue'),
						props: { isNew: true },
						meta: { title: 'Create Product', allowedRoles: [UserRole.Administrator] },
					},
					{
						name: 'EditProduct',
						path: 'products/:id',
						component: () => import(/* webpackChunkName: "admin" */ '../features/admin/products/EditProduct.vue'),
						props: true,
						meta: { title: 'Edit Product', allowedRoles: [UserRole.Administrator] },
					},
					{
						name: 'AdminProductAvailabilities',
						path: 'product-availabilities',
						component: () => import(/* webpackChunkName: "admin" */ '../features/admin/products/ProductAvailabilities.vue'),
						meta: { title: 'Product Availabilities', allowedRoles: [UserRole.Administrator] },
					},
					{
						name: 'AdminProductTypes',
						path: 'product-types',
						component: () => import(/* webpackChunkName: "admin" */ '../features/admin/products/ProductTypes.vue'),
						meta: { title: 'Product Types', allowedRoles: [UserRole.Administrator] },
					},
					{
						name: 'Employees',
						path: 'employees',
						component: () =>
							import(/* webpackChunkName: "user-management" */ '../features/admin/employees/components/EmployeeList.vue'),
						meta: { title: 'Employees', allowedRoles: [UserRole.Administrator] },
					},
					{
						name: 'ApiIntegrations',
						path: 'integrations',
						component: () => import(/* webpackChunkName: "admin" */ '../features/admin/integrations/IntegrationList.vue'),
						meta: { title: 'API Integrations', allowedRoles: [UserRole.Administrator] },
					},
					{
						name: 'NewApiIntegration',
						path: 'integrations/new',
						component: () => import(/* webpackChunkName: "admin" */ '../features/admin/integrations/EditIntegration.vue'),
						props: { isNew: true },
						meta: { title: 'API Integrations', allowedRoles: [UserRole.Administrator] },
					},
					{
						name: 'EditApiIntegration',
						path: 'integrations/:id',
						component: () => import(/* webpackChunkName: "admin" */ '../features/admin/integrations/EditIntegration.vue'),
						props: true,
						meta: { title: 'API Integrations', allowedRoles: [UserRole.Administrator] },
					},
				],
			},
			{
				path: 'unauthorized',
				name: 'Unauthorized',
				component: UnauthorizedView,
				meta: { title: 'Unauthorized' },
			},
		],
		beforeEnter: (to, from, next) => {
			if (isAuthenticated()) {
				const userRoles = getUser().userRoles || [];
				if (
					to.name !== 'Unauthorized' &&
					to.meta?.allowedRoles &&
					to.meta.allowedRoles.filter((f) => userRoles.includes(f)).length === 0
				) {
					next({ name: 'Unauthorized' });
				} else {
					next();
				}
			} else next({ name: 'Login' });
		},
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
	},
	{
		path: '/confirm-account',
		name: 'ConfirmAccount',
		component: ConfirmAccount,
	},
];

const isAuthenticated = () => {
	return !!localStorage.getItem('id_token');
};

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;

declare module 'vue-router' {
	interface RouteMeta {
		title?: string;
		allowedRoles?: UserRole[];
	}
}
