import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "column" }
const _hoisted_3 = { class: "column" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "column" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "column" }
const _hoisted_8 = { class: "column" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "column" }
const _hoisted_11 = { class: "column" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "column" }
const _hoisted_14 = { class: "column" }
const _hoisted_15 = {
  key: 0,
  class: "row"
}
const _hoisted_16 = { class: "column" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "column" }
const _hoisted_19 = { class: "column" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "column" }
const _hoisted_22 = { class: "column" }
const _hoisted_23 = { class: "column" }
const _hoisted_24 = { class: "row" }
const _hoisted_25 = { class: "column" }
const _hoisted_26 = { class: "column" }
const _hoisted_27 = { class: "row" }
const _hoisted_28 = { class: "column" }
const _hoisted_29 = { class: "column" }
const _hoisted_30 = { class: "row" }
const _hoisted_31 = { class: "column" }
const _hoisted_32 = {
  key: 0,
  class: "actions"
}
const _hoisted_33 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_zoomin = _resolveComponent("zoomin")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_data_grid = _resolveComponent("data-grid")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_close = _resolveComponent("close")!
  const _component_check = _resolveComponent("check")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_form, {
      ref: "form",
      model: _ctx.flatPerson,
      rules: _ctx.formRules,
      "label-position": "left",
      "label-width": "150px",
      class: "form"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tabs, {
          modelValue: _ctx.activeTab,
          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.activeTab) = $event)),
          stretch: true,
          "tab-position": _ctx.tabPosition
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, { name: "personInfo" }, {
              label: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass({ 'tab-error': _ctx.hasPersonInfoErrors })
                }, _toDisplayString(_ctx.i18n.getLocalised('person.personInfo')), 3)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.i18n.getLocalised('person.firstName'),
                      prop: "firstName"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.flatPerson.firstName,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.flatPerson.firstName) = $event)),
                          disabled: _ctx.readOnly
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.i18n.getLocalised('person.lastName'),
                      prop: "lastName"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.flatPerson.lastName,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.flatPerson.lastName) = $event)),
                          disabled: _ctx.readOnly
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.i18n.getLocalised('person.cnp'),
                      prop: "cnp"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.flatPerson.cnp,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.flatPerson.cnp) = $event)),
                          disabled: _ctx.readOnly && !_ctx.allowCriticalChanges
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _cache[21] || (_cache[21] = _createElementVNode("div", { class: "column" }, null, -1))
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.i18n.getLocalised('person.idCardSeries'),
                      prop: "idCardSeries"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.flatPerson.idCardSeries,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.flatPerson.idCardSeries) = $event)),
                          disabled: _ctx.readOnly
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.i18n.getLocalised('person.idCardNumber'),
                      prop: "idCardNumber"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.flatPerson.idCardNumber,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.flatPerson.idCardNumber) = $event)),
                          disabled: _ctx.readOnly
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.i18n.getLocalised('person.idCardIssueDate'),
                      prop: "idCardIssueDate"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          type: "date",
                          modelValue: _ctx.flatPerson.idCardIssueDate,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.flatPerson.idCardIssueDate) = $event)),
                          format: "DD.MM.YYYY",
                          "value-format": "YYYY-MM-DD",
                          disabled: _ctx.readOnly
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.i18n.getLocalised('person.idCardExpiryDate'),
                      prop: "idCardExpiryDate"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          type: "date",
                          modelValue: _ctx.flatPerson.idCardExpiryDate,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.flatPerson.idCardExpiryDate) = $event)),
                          format: "DD.MM.YYYY",
                          "value-format": "YYYY-MM-DD",
                          disabled: _ctx.readOnly
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.i18n.getLocalised('person.phoneNumber'),
                      prop: "phoneNumber"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.flatPerson.phoneNumber,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.flatPerson.phoneNumber) = $event)),
                          disabled: _ctx.readOnly
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.i18n.getLocalised('person.email'),
                      prop: "email"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.flatPerson.email,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.flatPerson.email) = $event)),
                          disabled: _ctx.readOnly,
                          type: "email"
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ])
                ]),
                (!_ctx.isNew)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("div", _hoisted_18, [
                            _createVNode(_component_el_button, {
                              flat: "",
                              type: "default",
                              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.uploadFileCtrl.click()))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(!!_ctx.flatPerson.idCard
										? _ctx.i18n.getLocalised('person.uploadNewIdCard')
										: _ctx.i18n.getLocalised('person.uploadIdCard')), 1)
                              ]),
                              _: 1
                            }),
                            _createElementVNode("input", {
                              ref: "uploadFileCtrl",
                              type: "file",
                              onChange: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.uploadFile && _ctx.uploadFile(...args))),
                              accept: "image/*,.pdf"
                            }, null, 544)
                          ]),
                          _createElementVNode("div", _hoisted_19, [
                            _createVNode(_component_el_button, {
                              flat: "",
                              type: "default",
                              onClick: _ctx.viewIdCard
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_icon, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_zoomin)
                                  ]),
                                  _: 1
                                }),
                                _createTextVNode(" " + _toDisplayString(_ctx.i18n.getLocalised('person.showIdCard')), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ])
                        ])
                      ]),
                      _cache[22] || (_cache[22] = _createElementVNode("div", { class: "column" }, null, -1))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, { name: "personAddress" }, {
              label: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass({ 'tab-error': _ctx.hasAddressErrors })
                }, _toDisplayString(_ctx.i18n.getLocalised('person.address')), 3)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.i18n.getLocalised('person.country'),
                      prop: "country"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.flatPerson.country,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.flatPerson.country) = $event)),
                          disabled: _ctx.readOnly
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.i18n.getLocalised('person.county'),
                      prop: "county"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.flatPerson.county,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.flatPerson.county) = $event)),
                          disabled: _ctx.readOnly
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.i18n.getLocalised('person.city'),
                      prop: "city"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.flatPerson.city,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.flatPerson.city) = $event)),
                          disabled: _ctx.readOnly
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.i18n.getLocalised('person.street'),
                      prop: "street"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.flatPerson.street,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.flatPerson.street) = $event)),
                          disabled: _ctx.readOnly
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.i18n.getLocalised('person.streetNo'),
                      prop: "streetNumber"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.flatPerson.streetNumber,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.flatPerson.streetNumber) = $event)),
                          disabled: _ctx.readOnly
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("div", _hoisted_28, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.i18n.getLocalised('person.building')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.flatPerson.building,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.flatPerson.building) = $event)),
                          disabled: _ctx.readOnly
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _createElementVNode("div", _hoisted_29, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.i18n.getLocalised('person.buildingNo')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.flatPerson.buildingNumber,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.flatPerson.buildingNumber) = $event)),
                          disabled: _ctx.readOnly
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("div", _hoisted_31, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.i18n.getLocalised('person.apartment')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.flatPerson.apartment,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.flatPerson.apartment) = $event)),
                          disabled: _ctx.readOnly
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _cache[23] || (_cache[23] = _createElementVNode("div", { class: "column" }, null, -1))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, { name: "contactMethods" }, {
              label: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass({ 'tab-error': _ctx.hasAddressErrors })
                }, _toDisplayString(_ctx.i18n.getLocalised('person.contactMethods')), 3)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_data_grid, {
                  "table-data": _ctx.flatPerson.contactMethods
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      prop: "type",
                      label: "Type"
                    }, {
                      default: _withCtx((scope) => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.ContactMethodType[scope.row.type]), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_table_column, {
                      prop: "value",
                      label: "Value"
                    })
                  ]),
                  _: 1
                }, 8, ["table-data"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "tab-position"])
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    (!_ctx.readOnly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
          _createVNode(_component_el_button, {
            circle: "",
            type: "default",
            onClick: _ctx.cancel
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_close)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            circle: "",
            type: "primary",
            onClick: _ctx.submitForm
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_check)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.showIdCard,
      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.showIdCard) = $event)),
      "close-on-click-modal": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: 'data:image/png;base64,' + _ctx.flatPerson.idCard,
          style: {"width":"90%","height":"40%"}
        }, null, 8, _hoisted_33)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}