import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "action-bar" }
const _hoisted_2 = { style: {"display":"flex","flex-direction":"row","gap":"3rem"} }
const _hoisted_3 = { style: {"display":"flex","flex-direction":"row"} }
const _hoisted_4 = { style: {"display":"flex","flex-direction":"row"} }
const _hoisted_5 = { class: "default-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plus = _resolveComponent("plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_edit = _resolveComponent("edit")!
  const _component_delete = _resolveComponent("delete")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_refresh = _resolveComponent("refresh")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.addNew)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                circle: "",
                type: "success",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addRecord()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_plus)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.showDetails)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 1,
                circle: "",
                type: "primary",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openRecord())),
                disabled: !_ctx.selectedRow
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_edit)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["disabled"]))
            : _createCommentVNode("", true),
          (_ctx.deleteItem)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 2,
                circle: "",
                type: "danger",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteRecord())),
                disabled: !_ctx.selectedRow
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_delete)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["disabled"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (!!_ctx.filterFunc)
          ? (_openBlock(), _createBlock(_component_el_input, {
              key: 0,
              modelValue: _ctx.filterValue,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filterValue) = $event)),
              clearable: "",
              placeholder: "Search",
              class: "search-input"
            }, {
              suffix: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("i", { class: "el-icon-search" }, null, -1)
              ])),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true),
        (_ctx.showRefresh)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 1,
              flat: "",
              type: "default",
              onClick: _ctx.onRefresh
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_refresh)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_el_table, {
      data: _ctx.pagedData,
      height: _ctx.height,
      width: _ctx.width,
      "default-sort": _ctx.defaultSort,
      "highlight-current-row": _ctx.highlightCurrentRow,
      onCurrentChange: _ctx.onRowClick,
      onSortChange: _ctx.onSortChanged,
      onRowDblclick: _ctx.openRecord
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["data", "height", "width", "default-sort", "highlight-current-row", "onCurrentChange", "onSortChange", "onRowDblclick"]),
    _createVNode(_component_el_pagination, {
      layout: "total, prev, pager, next, sizes",
      total: _ctx.totalItems,
      currentPage: _ctx.currentPage,
      "page-size": _ctx.pageSize,
      "page-sizes": [25, 50, 100],
      onCurrentChange: _ctx.currentPageChanged,
      onSizeChange: _ctx.pageSizeChanged
    }, null, 8, ["total", "currentPage", "page-size", "onCurrentChange", "onSizeChange"])
  ], 64))
}