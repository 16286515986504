<template>
	<div class="container">
		<el-card shadow="always" header="Login">
			<div class="card-body" @keyup.enter="submitForm">
				<el-steps :active="activeStep" :align-center="true">
					<el-step title="Credentials"></el-step>
					<el-step title="OTP"> </el-step>
				</el-steps>
				<el-form
					v-if="activeStep === 1"
					ref="login"
					:model="loginForm"
					:rules="formRules"
					label-position="left"
					label-width="100px"
					class="login-form"
					@submit.prevent
				>
					<el-form-item label="Email" prop="username">
						<el-input v-model="loginForm.username" placeholder="Email" />
					</el-form-item>
					<el-form-item label="Password" prop="password">
						<el-input v-model="loginForm.password" placeholder="Password" type="password" />
					</el-form-item>
				</el-form>
				<el-form
					v-if="activeStep === 2"
					ref="otp"
					:model="otpForm"
					:rules="otpRules"
					label-position="left"
					label-width="100px"
					class="login-form"
					@submit.prevent
				>
					<el-form-item label="OTP" prop="otp">
						<el-input v-model="otpForm.otp" placeholder="OTP" />
					</el-form-item>
				</el-form>
				<div class="actions">
					<el-button circle @click="submitForm"
						><el-icon><check /></el-icon
					></el-button>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script lang="ts">
import { checkOTP, loginUser } from '@/common/services/user.service';
import { defineComponent, reactive, ref } from 'vue';
import router from '@/router';
import { ElNotification } from 'element-plus';

export default defineComponent({
	setup() {
		const login = ref();
		const otp = ref();
		let sessionId = '';
		const activeStep = ref(1);

		const loginForm = reactive({
			username: '',
			password: '',
		});
		const formRules = ref({
			username: [
				{
					required: true,
					message: 'Enter your username',
					trigger: 'blur',
				},
			],
			password: [
				{
					required: true,
					message: 'Enter your password',
					trigger: 'blur',
				},
			],
		});
		const otpForm = reactive({
			otp: '',
		});
		const otpRules = ref({
			otp: [
				{
					required: true,
					message: 'Enter the number received via SMS',
					trigger: 'blur',
				},
			],
		});
		const submitForm = (e: any) => {
			e.preventDefault();
			if (activeStep.value === 1) {
				login.value.validate(async (valid: boolean) => {
					if (valid) {
						const result = await loginUser(loginForm.username, loginForm.password);
						sessionId = result.sessionId;
						if (result) activeStep.value = 2;
					}
				});
			} else if (activeStep.value === 2) {
				otp.value.validate(async (valid: boolean) => {
					if (valid) {
						try {
							const result = await checkOTP(sessionId, otpForm.otp);
							if (result != null) router.push({ name: 'DashboardWidgets' });
						} catch (err: any) {
							//TODO: handle auth error
							ElNotification.error({
								title: 'Error',
								message: err.message,
							});
						}
					}
				});
			}
		};
		return {
			activeStep,
			login,
			loginForm,
			formRules,
			otp,
			otpForm,
			otpRules,
			submitForm,
		};
	},
});
</script>

<style scoped lang="scss">
.container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;

	place-content: center;
	place-items: center;
}

.login-form {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 1rem;
}
</style>
