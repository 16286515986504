<template>
	<div class="actions">
		<el-button type="primary" size="small" @click="markAsFulfilled" v-if="allowFulfillment"
			><el-icon :size="20"><check /></el-icon>{{ i18n.getLocalised('certificateRequests.actions.approve') }}</el-button
		>
		<el-button type="success" size="small" @click="approveCertificateRequests" v-if="allowApprovalActions"
			><el-icon :size="20"><check /></el-icon>{{ i18n.getLocalised('certificateRequests.actions.approve') }}</el-button
		>
		<el-button type="danger" size="small" @click="rejectCertificateRequests" v-if="allowApprovalActions"
			><el-icon :size="20"><close /></el-icon>{{ i18n.getLocalised('certificateRequests.actions.reject') }}</el-button
		>
	</div>
	<el-descriptions title="Request Info">
		<el-descriptions-item label="Product">{{ model.product.code }} - {{ model.product.name }}</el-descriptions-item>
		<el-descriptions-item label="Status">
			<el-tag :type="getStatusTag()">
				{{ model.requestStatus.value }}
			</el-tag>
		</el-descriptions-item>
		<el-descriptions-item label="Source"> {{ model.requestSource.value }} </el-descriptions-item>
		<el-descriptions-item label="Created On">{{ formatDateTime(model.createdOn) }}</el-descriptions-item>
		<el-descriptions-item label="Password">
			<span v-if="hidePass">{{ "*".repeat(10) }}</span>
			<span v-else>{{ model.password }}</span>
			<el-button link :type="hidePass ? 'success' : 'danger'" @click="hidePass = !hidePass">
				<el-icon v-if="hidePass"><icon-view /></el-icon>
				<el-icon v-else><icon-hide /></el-icon>
			</el-button>
		</el-descriptions-item>
	</el-descriptions>
	<el-tabs v-model="activeTab" :stretch="true" @tab-change="onTabChange">
		<el-tab-pane :label="i18n.getLocalised('signatory.basicInfo')" name="signatoryInfo">
			<client-info :tabPosition="'left'" :model="model.person"></client-info>
		</el-tab-pane>
		<el-tab-pane label="Validation Session" name="validationSession">
			<validation-session-edit
				v-if="!!model.validationSession"
				:session="model.validationSession"
				:session-log="validationSessionLog"
				:resendStepFn="resendValidationStep"
				:validateStepFn="updateValidationStep"
				@on-valdiation-status-change="reloadSession"
			></validation-session-edit>
			<span v-else>No validation session</span>
		</el-tab-pane>
	</el-tabs>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';

import * as certificateRequestService from '../services/certificateRequest.service';

import ClientInfo from '@/features/clients/components/ClientInfo.vue';
import ValidationSessionEdit from '@/features/clients/components/ValidationSessionEdit.vue';
import { ValidationSessionLog } from '@/features/clients/models/validation-session-log.model';
import { CertificateRequestStatus } from '../models/certificate-request.model';
import { formatDateTime } from '@/common/component-utils';

import * as certRequestService from '../services/certificateRequest.service';

export default defineComponent({
	props: ['model'],
	components: { ClientInfo, ValidationSessionEdit },
	emits: ['onStatusChange'],
	setup(props, { emit }) {
		const state = reactive({
			model: props.model,
			activeTab: 'signatoryInfo',
			validationSessionLog: new Array<ValidationSessionLog>(),
			hidePass: true
		});

		watch(() => props.model, (val) => {
			console.log('Model changed', val);
			state.model = {
				...val
			};
		});
		
		const allowApprovalActions = computed(() => {
			return state.model.requestStatus.key === CertificateRequestStatus.PendingApproval.key;
		});

		const allowFulfillment = computed(() => {
			return state.model.requestStatus.key === CertificateRequestStatus.PendingPhysicalSetup.key;
		});

		async function onTabChange(name: string) {
			if(name === "validationSession") {
				await loadValidationSessionLogs();
			}
		}

		async function loadValidationSessionLogs() {
			if(state.validationSessionLog.length > 0) {
				return;
			}
			state.validationSessionLog = await certificateRequestService.getValidationSessionLogs(state.model.id!);
		}
		
		async function approveCertificateRequests() {
			await certRequestService.setApprovalStatus(state.model.id!, true);
			emit('onStatusChange');
		}

		async function rejectCertificateRequests() {
			await certRequestService.setApprovalStatus(state.model.id!, false);
			emit('onStatusChange');
		}

		async function markAsFulfilled() {
			await certRequestService.markAsFulfilled(state.model.id!);
			emit('onStatusChange');
		}

		async function reloadSession() {
			emit('onStatusChange');
		}

		async function resendValidationStep(sessionId: number, stepId: number) {
			await certRequestService.resendValidationStep(state.model.id!, sessionId, stepId);
		}

		async function updateValidationStep(sessionId: number, stepId: number, resolution: boolean, comments: string) {
			await certRequestService.updateValidationStep(state.model.id!, sessionId, stepId, resolution, comments);
		}

		function getStatusTag() {
			switch (state.model.requestStatus.key) {
				case CertificateRequestStatus.PendingApproval.key:
					return 'warning';
				case CertificateRequestStatus.Approved.key:
					return 'success';
				case CertificateRequestStatus.Rejected.key:
					return 'danger';
				case CertificateRequestStatus.Completed.key:
					return 'info';
				default:
					return '';
			}
		}

		return {
			...toRefs(state),
			allowApprovalActions,
			allowFulfillment,
			onTabChange,
			getStatusTag,
			formatDateTime,
			approveCertificateRequests,
			rejectCertificateRequests,
			markAsFulfilled,
			resendValidationStep,
			updateValidationStep,
			reloadSession
		};
	}
});
</script>

<style scoped></style>