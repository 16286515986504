import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_step = _resolveComponent("el-step")!
  const _component_el_steps = _resolveComponent("el-steps")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_check = _resolveComponent("check")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_card, {
      shadow: "always",
      header: "Login"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "card-body",
          onKeyup: _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["enter"]))
        }, [
          _createVNode(_component_el_steps, {
            active: _ctx.activeStep,
            "align-center": true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_step, { title: "Credentials" }),
              _createVNode(_component_el_step, { title: "OTP" })
            ]),
            _: 1
          }, 8, ["active"]),
          (_ctx.activeStep === 1)
            ? (_openBlock(), _createBlock(_component_el_form, {
                key: 0,
                ref: "login",
                model: _ctx.loginForm,
                rules: _ctx.formRules,
                "label-position": "left",
                "label-width": "100px",
                class: "login-form",
                onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    label: "Email",
                    prop: "username"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: _ctx.loginForm.username,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loginForm.username) = $event)),
                        placeholder: "Email"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "Password",
                    prop: "password"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: _ctx.loginForm.password,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loginForm.password) = $event)),
                        placeholder: "Password",
                        type: "password"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model", "rules"]))
            : _createCommentVNode("", true),
          (_ctx.activeStep === 2)
            ? (_openBlock(), _createBlock(_component_el_form, {
                key: 1,
                ref: "otp",
                model: _ctx.otpForm,
                rules: _ctx.otpRules,
                "label-position": "left",
                "label-width": "100px",
                class: "login-form",
                onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    label: "OTP",
                    prop: "otp"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: _ctx.otpForm.otp,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.otpForm.otp) = $event)),
                        placeholder: "OTP"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model", "rules"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_button, {
              circle: "",
              onClick: _ctx.submitForm
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_check)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ], 32)
      ]),
      _: 1
    })
  ]))
}