import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"margin-left":"10px"} }
const _hoisted_2 = { style: {"margin-left":"10px"} }
const _hoisted_3 = { style: {"margin-left":"10px"} }
const _hoisted_4 = { style: {"margin-left":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.orderItems,
    height: "400",
    style: {"width":"100%"},
    "highlight-current-row": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        prop: "product",
        label: "Product",
        sortable: ""
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("span", null, _toDisplayString(scope.row.product.name), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        prop: "quantity",
        label: "Quantity",
        sortable: ""
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(scope.row.quantity), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        prop: "originalPrice",
        label: "Product Price",
        sortable: ""
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(scope.row.originalPrice.price) + " " + _toDisplayString(scope.row.originalPrice.currencyCode), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        prop: "discount",
        label: "Discount",
        sortable: ""
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("span", _hoisted_3, _toDisplayString(scope.row.discount), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        prop: "price",
        label: "Price",
        sortable: ""
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("span", _hoisted_4, _toDisplayString(scope.row.price.price) + " " + _toDisplayString(scope.row.price.currencyCode), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"]))
}