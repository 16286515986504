import axios from 'axios';
import { environment } from '../environment';
import { AppUser } from '../models/app-user.model';

const baseUrl = `${environment.apiUrl}/accounts`;
let user: AppUser;

const loginUser = async (username: string, password: string): Promise<any> => {
	try {
		return axios.post(`${baseUrl}/login`, { email: username, password: password }).then((response) => {
			return response.data;
		});
	} catch (err: any) {
		return Promise.reject(err);
	}
};

const checkOTP = async (sessionId: string, otp: string): Promise<AppUser> => {
	try {
		return axios.post(`${baseUrl}/validate-token`, { sessionId: sessionId, otpCode: otp }).then((response) => {
			localStorage.setItem('id_token', response.data.id_token);
			user = new AppUser(response.data.id_token);
			return user;
		});
	} catch (err: any) {
		return Promise.reject(err);
	}
};

const getUser = () => {
	if (!user) {
		const jwt = localStorage.getItem('id_token');
		if (jwt) {
			user = new AppUser(jwt);
		}
	}
	return user;
};

const logoutUser = async (): Promise<void> => {
	try {
		localStorage.removeItem('id_token');
		return Promise.resolve();
	} catch (err: any) {
		return Promise.reject(err);
	}
};

export { loginUser, checkOTP, getUser, logoutUser };

export enum UserRole {
	Administrator = 'Administrator',
	BackofficeEmployee = 'BackofficeEmployee',
	ServiceUser = 'ServiceUser',
	Client = 'Client',
}
