<template>
	<el-descriptions title="Order Info">
		<el-descriptions-item label="Email">{{ order.email }}</el-descriptions-item>
		<el-descriptions-item label="Phone Number">{{ order.phoneNumber }}</el-descriptions-item>
		<el-descriptions-item label="Order Date">{{ formatDateTime(order.createdOn) }}</el-descriptions-item>
		<el-descriptions-item label="Order Status">{{ OrderStatus[order.orderStatus] }}</el-descriptions-item>
		<el-descriptions-item label="Order Value">{{ order.finalValue.price }} {{ order.finalValue.currencyCode }}</el-descriptions-item>
		<el-descriptions-item label="Payment Status">{{ PaymentStatus[order.paymentStatus!] }}</el-descriptions-item>
		<el-descriptions-item label="Payment Method">{{ PaymentMethod[order.paymentMethod!] }}</el-descriptions-item>
	</el-descriptions>
	<el-divider></el-divider>
	<el-descriptions title="Invoice Info">
		<el-descriptions-item label="Person Type">{{ PersonType[order.personType] }}</el-descriptions-item>
		<el-descriptions-item></el-descriptions-item>
		<el-descriptions-item></el-descriptions-item>
		<el-descriptions-item label="Company Name" v-if="order.personType === PersonType.LegalPerson">{{
			order.companyName
		}}</el-descriptions-item>
		<el-descriptions-item label="CUI" v-if="order.personType === PersonType.LegalPerson">{{ order.cui }}</el-descriptions-item>
		<el-descriptions-item label="ONRC#" v-if="order.personType === PersonType.LegalPerson">{{ order.onrcNumber }}</el-descriptions-item>
		<el-descriptions-item label="Is Tax Payer" v-if="order.personType === PersonType.LegalPerson">{{
			order.isTaxPayer ? 'Yes' : 'No'
		}}</el-descriptions-item>
		<el-descriptions-item></el-descriptions-item>
		<el-descriptions-item></el-descriptions-item>
		<el-descriptions-item label="Invoice Address">
			{{
				order.invoiceAddress
					? order.invoiceAddress.street ??
					  '' + ' ' + order.invoiceAddress.streetNumber ??
					  '' + ' ' + order.invoiceAddress.building ??
					  '' + ' ' + order.invoiceAddress.buildingNumber ??
					  ''
					: '-'
			}}
		</el-descriptions-item>
		<el-descriptions-item label="City">
			{{ order.invoiceAddress ? order.invoiceAddress.city : '-' }}
		</el-descriptions-item>
		<el-descriptions-item label="County">
			{{ order.invoiceAddress ? order.invoiceAddress.county : '-' }}
		</el-descriptions-item>
		<el-descriptions-item label="Country">
			{{ order.invoiceAddress ? order.invoiceAddress.country : '-' }}
		</el-descriptions-item>
	</el-descriptions>
	<el-divider></el-divider>
	<el-descriptions title="Delivery Info">
		<el-descriptions-item label="Delivery Method">{{DeliveryMethod[order.deliveryMethod!]}}</el-descriptions-item>
		<el-descriptions-item label="Delivery Address">
			{{
				order.deliveryAddress
					? order.deliveryAddress.street ??
					  '' + ' ' + order.deliveryAddress.streetNumber ??
					  '' + ' ' + order.deliveryAddress.building ??
					  '' + ' ' + order.deliveryAddress.buildingNumber ??
					  ''
					: '-'
			}}
		</el-descriptions-item>
		<el-descriptions-item label="City">
			{{ order.deliveryAddress ? order.deliveryAddress.city : '-' }}
		</el-descriptions-item>
		<el-descriptions-item label="County">
			{{ order.deliveryAddress ? order.deliveryAddress.county : '-' }}
		</el-descriptions-item>
		<el-descriptions-item label="Country">
			{{ order.deliveryAddress ? order.deliveryAddress.country : '-' }}
		</el-descriptions-item>
	</el-descriptions>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, watch } from 'vue';

import { Order, PaymentStatus, PaymentMethod, OrderStatus, DeliveryMethod, PersonType } from '../models/order.model';

import { formatDateTime } from '@/common/component-utils';

export default defineComponent({
	props: ['orderInfo'],
	setup(props) {
		const state = reactive({
			order: props.orderInfo as Order,
		});

		watch(
			() => props.orderInfo,
			() => {
				state.order = props.orderInfo as Order;
			}
		);

		return {
			...toRefs(state),
			PaymentStatus,
			PaymentMethod,
			OrderStatus,
			DeliveryMethod,
			PersonType,
			formatDateTime,
		};
	},
});
</script>

<style scoped lang="scss"></style>
