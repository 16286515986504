export const validateEmailAddress = (rule: any, value: string, callback: any) => {
	if (value) {
		const emailRegex = new RegExp('^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([a-z]{2,6}(?:\\.[a-z]{2})?)$');
		if (emailRegex.test(value)) callback();
		else callback(new Error('Invalid email address'));
	} else {
		callback(new Error('Email is mandatory'));
	}
};
export const validatePhoneNumber = (rule: any, value: string, callback: any) => {
	if (value) {
		const phoneNumberRegex = new RegExp('^([+]|00)?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$');
		if (phoneNumberRegex.test(value)) callback();
		else callback(new Error('Invalid phone number. Valid format: 0700111222, +40700111222, 0040700111222'));
	} else {
		callback(new Error('Phone number is mandatory'));
	}
};
