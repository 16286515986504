import { BaseEntity } from '@/common/models/base.model';
import { Company } from './company.model';
import { Signatory } from './signatory.model';
import { Person } from '@/common/models/person.model';
import { Product } from '@/features/admin/products/models/product.model';
import { Order } from '@/features/orders/models/order.model';
import { ClientCertificate } from '@/features/clients/models/client-certificate.model';
import { UserValidationSession } from '@/features/clients/models/user-validation-session.model';

export interface CertificateRequest extends BaseEntity {
	companyId: number | null;
	company: Company | null;
	signatoryId: number | null;
	signatory: Signatory | null;
	personId: number | null;
	person: Person | null;
	productId: number | null;
	product: Product | null;
	orderId: number | null;
	order: Order | null;
	requestStatus: CertificateRequestStatus;
	requestSource: CertificateRequestSource;
	certificateId: number | null;
	certificate: ClientCertificate | null;
	validationSessionId: number | null;
	validationSession: UserValidationSession | null;
	password: string | null;
}

export class CertificateRequestStatus {
	static New = new CertificateRequestStatus(1, 'New');
	static PendingValidation = new CertificateRequestStatus(2, 'PendingValidation');
	static PendingApproval = new CertificateRequestStatus(3, 'PendingApproval');
	static Approved = new CertificateRequestStatus(4, 'Approved');
	static Rejected = new CertificateRequestStatus(5, 'Rejected');
	static PendingFulfillment = new CertificateRequestStatus(6, 'PendingFulfillment');
	static Completed = new CertificateRequestStatus(7, 'Completed');
	static PendingPhysicalSetup = new CertificateRequestStatus(8, 'PendingPhysicalSetup');

	key: number;
	value: string;
	/**
	 *
	 */
	constructor(key: number, value: string) {
		this.key = key;
		this.value = value;
	}
}

export class CertificateRequestSource {
	static Web = new CertificateRequestSource(1, 'Web');
	static Api = new CertificateRequestSource(2, 'Api');
	static RegistrationForm = new CertificateRequestSource(3, 'RegistrationForm');

	key: number;
	value: string;
	/**
	 *
	 */
	constructor(key: number, value: string) {
		this.key = key;
		this.value = value;
	}
}
