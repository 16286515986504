import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"margin-left":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: $props.orderDocuments,
    height: "400",
    style: {"width":"100%"},
    "highlight-current-row": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        prop: "documentName",
        label: "Name",
        sortable: ""
      }),
      _createVNode(_component_el_table_column, {
        prop: "documentType",
        label: "Type",
        sortable: ""
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("span", null, _toDisplayString($setup.ClientDocumentType[scope.row.documentType]), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        prop: "createdOn",
        label: "Created On",
        sortable: ""
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("span", _hoisted_1, _toDisplayString($setup.formatDate(scope.row.createdOn)), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"]))
}