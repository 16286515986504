import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_data_grid = _resolveComponent("data-grid")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, {
    class: "card",
    "body-style": { height: 'calc(100% - 109px)', 'overflow-y': 'auto' }
  }, {
    header: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "card-header" }, [
        _createElementVNode("span", null, "Orders")
      ], -1)
    ])),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_data_grid, {
          "table-data": _ctx.tableData,
          "filter-func": _ctx.filter,
          height: "600",
          width: "900",
          "default-sort": { prop: 'createdOn', order: 'descending' },
          "highlight-current-row": "",
          "show-details": _ctx.openOrder,
          "show-refresh": true,
          "initial-state": _ctx.initialGridState,
          onOnRefresh: _ctx.refresh,
          onOnStateChange: _ctx.gridStateChanged
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              label: "Order No",
              sortable: "custom",
              "sort-by": "orderName"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("span", null, _toDisplayString(scope.row.orderName), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              prop: "createdOn",
              label: "Created On",
              sortable: "custom"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.formatDateTime(scope.row.createdOn)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              prop: "companyName",
              label: "Invoice",
              sortable: "custom"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("span", null, _toDisplayString(scope.row.companyName || scope.row.client.clientName), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "Order Status",
              sortable: "custom",
              "sort-by": "orderStatus"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.OrderStatus[scope.row.orderStatus]), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "Payment Method",
              sortable: "custom",
              "sort-by": "paymentMethod"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.PaymentMethod[scope.row.paymentMethod]), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "Payment Status",
              sortable: "custom",
              "sort-by": "paymentStatus"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.PaymentStatus[scope.row.paymentStatus]), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "CNP",
              sortable: "custom",
              "sort-by": "client.person.cnp"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("span", null, _toDisplayString(scope.row.client.person?.cnp ?? '-'), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              prop: "client.clientName",
              label: "Name",
              sortable: "custom"
            })
          ]),
          _: 1
        }, 8, ["table-data", "filter-func", "show-details", "initial-state", "onOnRefresh", "onOnStateChange"])
      ])
    ]),
    _: 1
  }))
}