<template>
	<div>
		<div class="container">
			<el-card shadow="always" header="Login" :body-style="{ height: 'calc(100% - 109px)', 'overflow-y': 'auto' }">
				<div class="card-body" v-if="activeStep > 0">
					<el-steps :active="activeStep" :align-center="true">
						<el-step title="Confirm Email"></el-step>
						<el-step title="Set Password"></el-step>
						<el-step title="OTP"> </el-step>
					</el-steps>
					<div v-if="activeStep === 1">
						<p>You have confirmed your email address. Click OK to move to the next step.</p>
					</div>
					<div v-if="activeStep === 2">
						<el-input label="Password" v-model="password" placeholder="Password" type="password" />
						<el-input label="Confirm Password" v-model="confirmPassword" placeholder="Repeat password" type="password" />
					</div>
					<div v-if="activeStep === 3">
						<el-input label="OTP" v-model="otp" placeholder="OTP" type="password" />
					</div>
					<div class="actions">
						<el-button circle type="primary" @click="submitForm" :disabled="!submitEnabled"
							><el-icon><check /></el-icon
						></el-button>
					</div>
				</div>
				<div class="card-body" v-else>
					<p>Error</p>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script lang="ts">
import {
	confirmEmployeeEmail,
	confirmPhoneNumber,
	generatePhoneValidationSession,
} from '@/features/admin/employees/services/employees.service';
import { ElNotification } from 'element-plus';
import { computed, defineComponent, onMounted, reactive, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
	setup() {
		const state = reactive({
			password: '',
			confirmPassword: '',
			otp: '',
			token: '',
			activeStep: 0,
			error: '',
			sessionId: '',
			sessionHash: '',
			userFlow: '',
		});

		const route = useRoute();
		const router = useRouter();

		const submitEnabled = computed(() => {
			return (
				state.activeStep === 1 ||
				(state.activeStep === 2 && state.password.length >= 6 && state.password === state.confirmPassword) ||
				(state.activeStep === 3 && state.otp)
			);
		});

		onMounted(async () => {
			if (route.query && route.query.token) {
				state.token = encodeURIComponent(route.query.token.toString());
				state.userFlow = route.query.userFlow?.toString() || '';
				try {
					await confirmEmployeeEmail(state.token);
					state.activeStep = 1;
				} catch (err: any) {
					ElNotification({
						type: 'error',
						message: err,
						title: 'Error',
						zIndex: 10000,
					});
				}
			} else {
				ElNotification({
					type: 'error',
					message: 'Invalid Session',
					title: 'Error',
					zIndex: 10000,
				});
			}
		});

		const submitForm = async () => {
			if (state.activeStep === 1) {
				state.activeStep = 2;
			} else if (state.activeStep === 2) {
				try {
					const response: any = await generatePhoneValidationSession(state.token);
					state.sessionId = response.sessionId;
					state.sessionHash = response.sessionHash;
					state.activeStep = 3;
				} catch (e: any) {
					state.activeStep = 0;
					ElNotification({
						type: 'error',
						message: e,
						title: 'Error',
						zIndex: 10000,
					});
				}
			} else if (state.activeStep === 3) {
				try {
					const otpConfirmation = {
						otpCode: state.otp,
						sessionId: state.sessionId,
						token: state.sessionHash,
						password: state.password,
						userFlow: state.userFlow
					};
					await confirmPhoneNumber(otpConfirmation);
					router.push({ name: 'Login' });
				} catch (e: any) {
					state.activeStep = 0;
					ElNotification({
						type: 'error',
						message: e,
						title: 'Error',
						zIndex: 10000,
					});
				}
			}
		};

		return {
			...toRefs(state),
			submitEnabled,
			submitForm,
		};
	},
});
</script>

<style scoped lang="scss">
.container {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: row;

	place-content: center;
	place-items: center;
}

.card-body {
	min-width: 300px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 1rem;
}
</style>
