<template>
	<div class="main">
		<div class="color-switcher">
			<el-switch v-model="isDark" inline-prompt :active-icon="Sunny" :inactive-icon="MoonNight" />
		</div>
		<router-view />
	</div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import { Sunny, MoonNight } from '@element-plus/icons-vue';

export default defineComponent({
	setup() {
		const isDark = ref(false);

		watch(
			() => isDark.value,
			() => {
				localStorage.setItem('darkMode', isDark.value ? '1' : '0');
				document.body.classList.add(isDark.value ? 'dark' : 'light');
				document.body.classList.remove(isDark.value ? 'light' : 'dark');
			}
		);

		onMounted(() => {
			isDark.value = localStorage.getItem('darkMode') === '1' ?? false;
		});
		return {
			isDark,
			Sunny,
			MoonNight,
		};
	},
});
</script>
<style lang="scss">

* {
	font-family: 'Nunito', sans-serif;
}

body {
	height: 100vh;
	width: 100vw;
	padding: 0;
	margin: 0;

	&.light {
		--background-color: #f5f5f5;
		--text-color: #333;
		--menu-background-from: #1d4ed8;
		--menu-background-to: #60a5fa;
	}

	&.dark {
		--background-color: #444;
		--text-color: #f5f5f5;
		--menu-background-from: #1d4ed8;
		--menu-background-to: #0d325f;
		--el-bg-color: #333;
		--el-bg-color-page: #333;
		--el-bg-color-overlay: #333;
		--el-text-color-primary: #f5f5f5;
		--el-text-color-regular: #c5c5c5;
		--el-text-color-secondary: #909399;
		--el-text-color-placeholder: #a8abb2;
		--el-text-color-disabled: #c0c4cc;
		--el-color-white: #333;
		--el-color-black: #f5f5f5;
		--el-border-color: #5c5d50;
		--el-border-color-light: #44474d;
		--el-border-color-lighter: #2b2e15;
		--el-border-color-extra-light: #12161c;
		--el-border-color-dark: #64676e;
		--el-border-color-darker: #7d8086;
		--el-fill-color: #303235;
		--el-fill-color-light: #15171a;
		--el-fill-color-lighter: #1a1a1a;
		--el-fill-color-extra-light: #0a0c0f;
		--el-fill-color-dark: #2b2d10;
		--el-fill-color-darker: #26282b;
		--el-fill-color-blank: #333;
		--el-disabled-bg-color: var(--el-fill-color-light);
		--el-disabled-text-color: var(--el-text-color-placeholder);
		--el-disabled-border-color: var(--el-border-color-light);
		--el-button-text-color: var(--el-text-color-primary);
		--el-box-shadow: 0px 10px 25px -10px #939393;

		.el-table {
			--el-table-current-row-bg-color: var(--el-fill-color-lighter);
		}

		.el-dialog {
			border-bottom: 1px solid var(--el-color-black);
			border-bottom-right-radius: 5px;
			border-bottom-left-radius: 5px;
		}
	}
}

.main {
	height: 100vh;
	width: 100vw;
	padding: 0;
	margin: 0;
	background: radial-gradient(circle at left top, var(--background-color) 65%, transparent),
		radial-gradient(circle at right bottom, var(--background-color) 20%, transparent);
	color: var(--text-color);
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;

	width: 100%;
	height: 100%;
	overflow: hidden;
}

#nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}

.el-loading-mask,
.loader-mask {
	z-index: 10000;
}

.color-switcher {
	position: fixed;
	bottom: 0;
	right: calc(50% - 50px);
	padding: 10px;
	border-radius: 0 0 0 5px;
}
</style>
