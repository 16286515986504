import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-header" }
const _hoisted_2 = { class: "header-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrowleft = _resolveComponent("arrowleft")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_refresh = _resolveComponent("refresh")!
  const _component_order_details = _resolveComponent("order-details")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, {
    class: "card",
    "body-style": { padding: '20px', 'overflow-y': 'auto', height: 'calc(100% - 106px)' }
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_button, { onClick: _ctx.cancel }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_arrowleft)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createTextVNode(" Order " + _toDisplayString(_ctx.order.orderName) + " ", 1),
          _createVNode(_component_el_button, { onClick: _ctx.reloadOrder }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_refresh)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_order_details, {
        model: _ctx.order,
        "validation-session-log": _ctx.validationSessionLog,
        onOnOrderChanged: _ctx.reloadOrder
      }, null, 8, ["model", "validation-session-log", "onOnOrderChanged"])
    ]),
    _: 1
  }))
}