<template>
	<data-grid
		:table-data="dataSource"
		:filter-func="filterData"
		:default-sort="{ prop: 'createdOn', order: 'descending' }"
		:height="height"
		highlight-current-row
		:show-details="showDetails"
		:delete-item="deleteItem"
		ref="table"
		:show-refresh="true"
		@on-refresh="refresh"
	>
		<template #actions>
			<el-button round type="success" @click="approveCertificateRequests" :disabled="!allowApprovalActions"
				><el-icon :size="20"><check /></el-icon>{{ i18n.getLocalised('certificateRequests.actions.approve') }}</el-button
			>
			<el-button round type="danger" @click="rejectCertificateRequests" :disabled="!allowApprovalActions"
				><el-icon :size="20"><close /></el-icon>{{ i18n.getLocalised('certificateRequests.actions.reject') }}</el-button
			>
		</template>
		<el-table-column prop="person.firstName" :label="i18n.getLocalised('certificateRequests.fields.firstName')" sortable="custom"></el-table-column>
		<el-table-column prop="person.lastName" :label="i18n.getLocalised('certificateRequests.fields.lastName')" sortable="custom"></el-table-column>
		<el-table-column prop="email" :label="i18n.getLocalised('certificateRequests.fields.email')"></el-table-column>
		<el-table-column prop="phoneNumber" :label="i18n.getLocalised('certificateRequests.fields.phoneNumber')"></el-table-column>
		<el-table-column prop="person.cnp" :label="i18n.getLocalised('certificateRequests.fields.cnp')" sortable="custom"></el-table-column>
		<el-table-column v-if="!!companies" prop="company.companyName" :label="i18n.getLocalised('certificateRequests.fields.companyName')" sortable="custom"></el-table-column>
		<el-table-column prop="requestStatus.value" :label="i18n.getLocalised('certificateRequests.fields.requestStatus')" sortable="custom"></el-table-column>
		<el-table-column prop="createdOn" :label="i18n.getLocalised('certificateRequests.fields.createdOn')" sortable="custom"></el-table-column>
	</data-grid>
	<el-dialog title="Certificate Request Details" v-model="showDetailsDialog" top="2vh" width="75%" destroy-on-close :close-on-click-modal="false">
		<certificate-request-details :model="detailsForRecord" @on-status-change="handleStatusChange" />
	</el-dialog>
</template>

<script lang="ts">
import DataGrid from '@/components/data-grid/DataGrid.vue';
import { computed, defineComponent, onMounted, reactive, toRefs, watch } from 'vue';
import { CertificateRequest, CertificateRequestStatus } from '../models/certificate-request.model';

import * as certRequestService from '../services/certificateRequest.service';

import CertificateRequestDetails from './CertificateRequestDetails.vue';

interface CertificateRequestListState {
	dataSource: CertificateRequest[];
	selectedRequests: CertificateRequest[];
	companyId: number | null;
	clients: boolean | null;
	companies: boolean | null;
	showDetailsDialog: boolean;
	detailsForRecord: CertificateRequest | null;
}

export default defineComponent({
	props: {
		companyId: {
			type: Number,
			default: null,
		},
		clients: {
			type: Boolean,
			default: null,
		},
		companies: {
			type: Boolean,
			default: null,
		},
		status: {
			type: Number,
			default: null
		},
		height: {
			type: String,
			default: '600px',
		},
	},
	components: { DataGrid, CertificateRequestDetails },
	setup(props) {
		const state = reactive<CertificateRequestListState>({
			dataSource: [],
			selectedRequests: [],
			companyId: props.companyId ?? null,
			clients: props.clients ?? null,
			companies: props.companies ?? null,
			showDetailsDialog: false,
			detailsForRecord: null,
		});

		const actions = {
			approveCertificateRequests,
			rejectCertificateRequests,
			requestSelected,
			filterData,
			showDetails,
			refresh,
			deleteItem
		};

		// onMounted(async () => { });

		watch(
			() => props.companyId,
			async (newValue, oldValue) => {
				if (newValue !== oldValue) {
					state.companyId = newValue;
					await loadDataSource();
				}
			}
		);

		watch(
			() => props.clients,
			async (newValue, oldValue) => {
				console.log('clients changed', newValue, oldValue);
				if (newValue !== oldValue) {
					state.clients = newValue;
					await loadDataSource();
				}
			}
		);

		watch(
			() => props.companies,
			async (newValue, oldValue) => {
				console.log('companies changed', newValue, oldValue);
				if (newValue !== oldValue) {
					state.companies = newValue;
					await loadDataSource();
				}
			}
		);

		onMounted(async () => {
			console.log('props: ', props);
			await loadDataSource();
		});

		const allowApprovalActions = computed(() => {
			return state.selectedRequests.length > 0 && state.selectedRequests.every(r => r.requestStatus.key === CertificateRequestStatus.PendingApproval.key);
		});

		async function loadDataSource() {
			console.log("Reload data");
			if(!props.clients && !props.companies && !props.companyId) {
				state.dataSource = [];
				return;
			}
			state.dataSource = (await certRequestService.getCertificateRequests(props.companyId, props.clients, props.companies, props.status))
				.sort((a, b) => {
					return a.createdOn! > b.createdOn! ? -1 : 1;
				});

			if(state.selectedRequests.length > 0) {
				console.log('Re-selecting requests');
				state.selectedRequests = [...state.dataSource.filter(r => state.selectedRequests.some(sr => sr.id === r.id))];
			}

			if(state.detailsForRecord) {
				console.log('Re-selecting details', state.dataSource);
				const session = state.dataSource.find(r => r.id === state.detailsForRecord!.id) ?? null;
				if(session) {
					await showDetails(session);
				}
			}
		}

		async function approveCertificateRequests() {
			for (let i = 0; i < state.selectedRequests.length; i++) {
				await certRequestService.setApprovalStatus(state.selectedRequests[i].id!, true);
			}
			state.selectedRequests = [];
			await loadDataSource();
		}

		async function rejectCertificateRequests() {
			for (let i = 0; i < state.selectedRequests.length; i++) {
				await certRequestService.setApprovalStatus(state.selectedRequests[i].id!, false);
			}
			state.selectedRequests = [];
			await loadDataSource();
		}

		async function handleStatusChange() {
			console.log('Status changed');
			await loadDataSource();
		}

		async function showDetails(item) {
			state.selectedRequests = [item];
			const id = state.selectedRequests[0].id!;
			state.detailsForRecord = await certRequestService.getCertificateRequest(id);
			state.showDetailsDialog = true;
		}

		function requestSelected(selection: any) {
			state.selectedRequests = [selection];
		}

		function filterData(f, value) {
			const row = f.value ?? f;
			return !!row.person &&
				(row.person!.phoneNumber?.indexOf(value) >= 0 ||
					row.person.cnp.indexOf(value) >= 0 ||
					row.person!.email?.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
					row.person.firstName.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
					row.person.lastName.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
					row.company?.name.toLowerCase().indexOf(value.toLowerCase()) >= 0);
		}

		async function deleteItem(item) {
			await certRequestService.deleteCertificateRequest(item.id!);
			await loadDataSource();
		}

		async function refresh() {
			await loadDataSource();
		}

		return {
			CertificateRequestStatus,
			...toRefs(state),
			allowApprovalActions,
			...actions,
			handleStatusChange,
		};
	},
});
</script>

<style scoped></style>