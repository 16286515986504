import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "card-body"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "actions" }
const _hoisted_7 = {
  key: 1,
  class: "card-body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_step = _resolveComponent("el-step")!
  const _component_el_steps = _resolveComponent("el-steps")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_check = _resolveComponent("check")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_card, {
        shadow: "always",
        header: "Login",
        "body-style": { height: 'calc(100% - 109px)', 'overflow-y': 'auto' }
      }, {
        default: _withCtx(() => [
          (_ctx.activeStep > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_el_steps, {
                  active: _ctx.activeStep,
                  "align-center": true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_step, { title: "Confirm Email" }),
                    _createVNode(_component_el_step, { title: "Set Password" }),
                    _createVNode(_component_el_step, { title: "OTP" })
                  ]),
                  _: 1
                }, 8, ["active"]),
                (_ctx.activeStep === 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[3] || (_cache[3] = [
                      _createElementVNode("p", null, "You have confirmed your email address. Click OK to move to the next step.", -1)
                    ])))
                  : _createCommentVNode("", true),
                (_ctx.activeStep === 2)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_el_input, {
                        label: "Password",
                        modelValue: _ctx.password,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
                        placeholder: "Password",
                        type: "password"
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_el_input, {
                        label: "Confirm Password",
                        modelValue: _ctx.confirmPassword,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.confirmPassword) = $event)),
                        placeholder: "Repeat password",
                        type: "password"
                      }, null, 8, ["modelValue"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.activeStep === 3)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_el_input, {
                        label: "OTP",
                        modelValue: _ctx.otp,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.otp) = $event)),
                        placeholder: "OTP",
                        type: "password"
                      }, null, 8, ["modelValue"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_el_button, {
                    circle: "",
                    type: "primary",
                    onClick: _ctx.submitForm,
                    disabled: !_ctx.submitEnabled
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_check)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[4] || (_cache[4] = [
                _createElementVNode("p", null, "Error", -1)
              ])))
        ]),
        _: 1
      })
    ])
  ]))
}