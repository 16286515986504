import { ClientCertificate } from '@/features/clients/models/client-certificate.model';
import { Address, emptyAddress } from './address.model';
import { BaseEntity } from './base.model';
import { ContactMethod } from './contact-method.model';

export interface Person extends BaseEntity {
	firstName: string;
	lastName: string;
	idCardSeries: string;
	idCardNumber: string;
	cnp: string;
	idCardIssueDate: Date;
	idCardExpiryDate: Date;
	agreedToTermsAndConditions: boolean;
	agreeToGDPR: boolean;
	address: Address;
	clientCertificates: Array<ClientCertificate>;
	idCard: any | null;
	contactMethods: Array<ContactMethod>;
	phoneNumber: string | null;
	email: string | null;
}

export interface CreateSignatory extends Person {
	createUser: boolean;
}

export const emptyPerson: Person = {
	id: 0,
	createdBy: null,
	createdOn: null,
	modifiedBy: null,
	modifiedOn: null,
	isDeleted: false,
	address: emptyAddress,
	agreeToGDPR: false,
	agreedToTermsAndConditions: false,
	cnp: '',
	firstName: '',
	idCardExpiryDate: new Date(2026, 1, 1),
	idCardIssueDate: new Date(2021, 1, 1),
	idCardNumber: '',
	idCardSeries: '',
	lastName: '',
	clientCertificates: [],
	idCard: null,
	contactMethods: [],
	phoneNumber: null,
	email: null,
};
