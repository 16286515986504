import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","flex-direction":"row","justify-content":"space-between"} }
const _hoisted_2 = { class: "card-actions" }
const _hoisted_3 = { style: {"display":"flex","flex-direction":"row"} }
const _hoisted_4 = { style: {"flex":"1","display":"flex","flex-direction":"column","justify-content":"center","align-items":"flex-start"} }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { style: {"flex":"2","display":"flex","flex-direction":"column","justify-content":"center","align-items":"flex-start"} }
const _hoisted_8 = { style: {"display":"flex","flex-direction":"row"} }
const _hoisted_9 = { style: {"flex":"1","display":"flex","flex-direction":"column","justify-content":"center","align-items":"flex-start"} }
const _hoisted_10 = { style: {"flex":"2","display":"flex","flex-direction":"column","justify-content":"center","align-items":"flex-start"} }
const _hoisted_11 = {
  key: 0,
  class: "person-info"
}
const _hoisted_12 = { class: "personal-info" }
const _hoisted_13 = { class: "id-card" }
const _hoisted_14 = { class: "actions" }
const _hoisted_15 = { class: "actions" }
const _hoisted_16 = { class: "left" }
const _hoisted_17 = {
  key: 0,
  class: "right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_timeline_item = _resolveComponent("el-timeline-item")!
  const _component_el_timeline = _resolveComponent("el-timeline")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_descriptions, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_descriptions_item, { label: "Session hash" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.session?.sessionHash), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.canChangeValidationSessionType)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            type: "primary",
            onClick: _ctx.changeValidationType,
            disabled: !_ctx.isOnlineValidation && !_ctx.isInPersonValidation
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.isOnlineValidation 
					? "Switch to In Person"
					: _ctx.isInPersonValidation ? "Switch to Online" : "Unknown"), 1)
            ]),
            _: 1
          }, 8, ["onClick", "disabled"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.currentTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTab) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: "Session",
          name: "session"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_timeline, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, idx) => {
                  return (_openBlock(), _createBlock(_component_el_timeline_item, {
                    key: idx,
                    timestamp: _ctx.formatDateTime(item.validatedOn),
                    color: _ctx.getNodeColor(item),
                    hollow: !item.validatedOn,
                    placement: "top"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_card, null, {
                        header: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            (!item.isValid &&
										(!!_ctx.clientId || !!_ctx.validateStepFn) &&
										(item.validationReason === 4 ||
										item.validationReason === 5 ||
										item.validationReason === 6 ||
										item.validationReason === 7)
									)
                              ? (_openBlock(), _createBlock(_component_el_button, {
                                  key: 0,
                                  type: "text",
                                  size: "small",
                                  onClick: ($event: any) => (_ctx.validate(item))
                                }, {
                                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                                    _createElementVNode("i", { class: "el-icon-check" }, null, -1),
                                    _createTextVNode(" Validate ")
                                  ])),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true),
                            ((!item.isValid && (!!_ctx.clientId || !!_ctx.resendStepFn) && (item.validationReason === 4 || item.validationReason === 5 || item.validationReason === 6)) || item.validationReason === 1)
                              ? (_openBlock(), _createBlock(_component_el_button, {
                                  key: 1,
                                  type: "text",
                                  size: "small",
                                  onClick: ($event: any) => (_ctx.resend(item))
                                }, {
                                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                                    _createElementVNode("i", { class: "el-icon-refresh" }, null, -1),
                                    _createTextVNode(" Resend ")
                                  ])),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("div", _hoisted_4, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.ValidationReason[item.validationReason]), 1),
                              (item.validatedOn)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(item.isValid ? 'Validated' : 'Rejected') + " by " + _toDisplayString(item.validatedBy ? item.validatedBy.username : 'N/A'), 1))
                                : (_openBlock(), _createElementBlock("p", _hoisted_6, "Pending..."))
                            ]),
                            _createElementVNode("div", _hoisted_7, [
                              _cache[10] || (_cache[10] = _createElementVNode("p", null, "Comments:", -1)),
                              _createElementVNode("p", null, _toDisplayString(item.comments || 'No comments'), 1)
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["timestamp", "color", "hollow"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "Audit",
          name: "audit"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_timeline, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sessionLog, (item, idx) => {
                  return (_openBlock(), _createBlock(_component_el_timeline_item, {
                    key: idx,
                    timestamp: _ctx.formatDateTime(item.createdOn),
                    placement: "top"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_card, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("div", _hoisted_9, [
                              _createElementVNode("p", null, _toDisplayString(item.contextType?.code) + " - " + _toDisplayString(item.eventType?.code), 1)
                            ]),
                            _createElementVNode("div", _hoisted_10, [
                              _cache[11] || (_cache[11] = _createElementVNode("p", null, "Originating IP", -1)),
                              _createElementVNode("p", null, _toDisplayString(item.originatingIp || 'N/A'), 1),
                              _cache[12] || (_cache[12] = _createElementVNode("p", null, "Response:", -1)),
                              _createElementVNode("p", null, _toDisplayString(item.response || 'No response'), 1)
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["timestamp"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.showValidateStep,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showValidateStep) = $event)),
      "close-on-click-modal": false,
      "destroy-on-close": ""
    }, {
      header: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.selectedStep ? _ctx.ValidationReason[_ctx.selectedStep!.validationReason] : ''), 1)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_el_button, {
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showValidateStep = false))
            }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode("Cancel")
              ])),
              _: 1
            })
          ]),
          (!!_ctx.clientId || !!_ctx.validateStepFn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createVNode(_component_el_button, {
                  type: "success",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.validateStep(true))),
                  disabled: !_ctx.person?.idCard
                }, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createTextVNode("Approve")
                  ])),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_el_button, {
                  type: "danger",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.validateStep(false)))
                }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode("Reject")
                  ])),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        (_ctx.selectedStep!.validationReason === _ctx.ValidationReason.InPersonValidation)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_el_descriptions, {
                  title: "Person Info",
                  size: "small",
                  direction: "vertical",
                  columns: "2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_descriptions_item, {
                      label: _ctx.i18n.getLocalised('person.firstName')
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.person?.firstName), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_descriptions_item, {
                      label: _ctx.i18n.getLocalised('person.lastName')
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.person?.lastName), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_descriptions_item, {
                      label: _ctx.i18n.getLocalised('person.cnp'),
                      span: "2"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.person?.cnp), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_descriptions_item, {
                      label: _ctx.i18n.getLocalised('person.idCardSeries')
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.person?.idCardSeries), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_descriptions_item, {
                      label: _ctx.i18n.getLocalised('person.idCardNumber')
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.person?.idCardNumber), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_descriptions_item, {
                      label: _ctx.i18n.getLocalised('person.idCardIssueDate')
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.person?.idCardIssueDate ?? null)), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_descriptions_item, {
                      label: _ctx.i18n.getLocalised('person.idCardExpiryDate')
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.person?.idCardExpiryDate ?? null)), 1)
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_descriptions, {
                  title: "Address",
                  size: "small",
                  direction: "vertical",
                  columns: "2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_descriptions_item, {
                      label: _ctx.i18n.getLocalised('person.street')
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.person?.address?.street), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_descriptions_item, {
                      label: _ctx.i18n.getLocalised('person.streetNo')
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.person?.address?.streetNumber), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_descriptions_item, {
                      label: _ctx.i18n.getLocalised('person.building')
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.person?.address?.building), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_descriptions_item, {
                      label: _ctx.i18n.getLocalised('person.buildingNo')
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.person?.address?.buildingNumber), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_descriptions_item, {
                      label: _ctx.i18n.getLocalised('person.apartment')
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.person?.address?.apartment), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_descriptions_item, {
                      label: _ctx.i18n.getLocalised('person.city')
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.person?.address?.city), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_descriptions_item, {
                      label: _ctx.i18n.getLocalised('person.county')
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.person?.address?.county), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_descriptions_item, {
                      label: _ctx.i18n.getLocalised('person.country')
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.person?.address?.country), 1)
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_el_image, {
                  style: {"width":"100%","height":"100%"},
                  src: `data:image/png;base64,${_ctx.person?.idCard}`,
                  fit: "contain",
                  "preview-src-list": `data:image/png;base64,${_ctx.person?.idCard}`
                }, null, 8, ["src", "preview-src-list"]),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_el_button, {
                    flat: "",
                    type: "default",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.uploadFileCtrl.click()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(!!_ctx.person?.idCard
						? _ctx.i18n.getLocalised('person.uploadNewIdCard')
						: _ctx.i18n.getLocalised('person.uploadIdCard')), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("input", {
                    ref: "uploadFileCtrl",
                    type: "file",
                    onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.uploadFile && _ctx.uploadFile(...args))),
                    accept: "image/*"
                  }, null, 544)
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_form, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: "Comments" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: _ctx.validateStepComments,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.validateStepComments) = $event)),
                  placeholder: "Validation comments"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}