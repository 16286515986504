import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "actions" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_check = _resolveComponent("check")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_close = _resolveComponent("close")!
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_icon_view = _resolveComponent("icon-view")!
  const _component_icon_hide = _resolveComponent("icon-hide")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_client_info = _resolveComponent("client-info")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_validation_session_edit = _resolveComponent("validation-session-edit")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.allowFulfillment)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            type: "primary",
            size: "small",
            onClick: _ctx.markAsFulfilled
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, { size: 20 }, {
                default: _withCtx(() => [
                  _createVNode(_component_check)
                ]),
                _: 1
              }),
              _createTextVNode(_toDisplayString(_ctx.i18n.getLocalised('certificateRequests.actions.approve')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.allowApprovalActions)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 1,
            type: "success",
            size: "small",
            onClick: _ctx.approveCertificateRequests
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, { size: 20 }, {
                default: _withCtx(() => [
                  _createVNode(_component_check)
                ]),
                _: 1
              }),
              _createTextVNode(_toDisplayString(_ctx.i18n.getLocalised('certificateRequests.actions.approve')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.allowApprovalActions)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 2,
            type: "danger",
            size: "small",
            onClick: _ctx.rejectCertificateRequests
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, { size: 20 }, {
                default: _withCtx(() => [
                  _createVNode(_component_close)
                ]),
                _: 1
              }),
              _createTextVNode(_toDisplayString(_ctx.i18n.getLocalised('certificateRequests.actions.reject')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_el_descriptions, { title: "Request Info" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_descriptions_item, { label: "Product" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.model.product.code) + " - " + _toDisplayString(_ctx.model.product.name), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions_item, { label: "Status" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tag, {
              type: _ctx.getStatusTag()
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.model.requestStatus.value), 1)
              ]),
              _: 1
            }, 8, ["type"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions_item, { label: "Source" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.model.requestSource.value), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions_item, { label: "Created On" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.formatDateTime(_ctx.model.createdOn)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions_item, { label: "Password" }, {
          default: _withCtx(() => [
            (_ctx.hidePass)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString("*".repeat(10)), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.model.password), 1)),
            _createVNode(_component_el_button, {
              link: "",
              type: _ctx.hidePass ? 'success' : 'danger',
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hidePass = !_ctx.hidePass))
            }, {
              default: _withCtx(() => [
                (_ctx.hidePass)
                  ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_icon_view)
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_icon_hide)
                      ]),
                      _: 1
                    }))
              ]),
              _: 1
            }, 8, ["type"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeTab) = $event)),
      stretch: true,
      onTabChange: _ctx.onTabChange
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: _ctx.i18n.getLocalised('signatory.basicInfo'),
          name: "signatoryInfo"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_client_info, {
              tabPosition: 'left',
              model: _ctx.model.person
            }, null, 8, ["model"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_tab_pane, {
          label: "Validation Session",
          name: "validationSession"
        }, {
          default: _withCtx(() => [
            (!!_ctx.model.validationSession)
              ? (_openBlock(), _createBlock(_component_validation_session_edit, {
                  key: 0,
                  session: _ctx.model.validationSession,
                  "session-log": _ctx.validationSessionLog,
                  resendStepFn: _ctx.resendValidationStep,
                  validateStepFn: _ctx.updateValidationStep,
                  onOnValdiationStatusChange: _ctx.reloadSession
                }, null, 8, ["session", "session-log", "resendStepFn", "validateStepFn", "onOnValdiationStatusChange"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_4, "No validation session"))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "onTabChange"])
  ], 64))
}