import { SimpleUser } from './user-dto.model';

export interface BaseEntity {
	id: number | null;
	createdOn: Date | null;
	modifiedOn: Date | null;
	createdBy: SimpleUser | null;
	modifiedBy: SimpleUser | null;
	isDeleted: boolean;
}

export const emptyBaseEntity: BaseEntity = {
	id: 0,
	createdOn: null,
	modifiedOn: null,
	createdBy: null,
	modifiedBy: null,
	isDeleted: false,
};
