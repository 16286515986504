import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "doc-panel" }
const _hoisted_2 = { class: "doc-viewer" }
const _hoisted_3 = { class: "doc-header" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "doc-list" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_download = _resolveComponent("download")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_pdf_viewer = _resolveComponent("pdf-viewer")!
  const _component_picturefilled = _resolveComponent("picturefilled")!
  const _component_document = _resolveComponent("document")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.selectedDocument)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.selectedDocument.fileName), 1),
              _createVNode(_component_el_button, {
                icon: "",
                type: "success",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadFile(_ctx.selectedDocument)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_download)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            (_ctx.selectedDocument.fileName.endsWith('.pdf'))
              ? (_openBlock(), _createBlock(_component_pdf_viewer, {
                  key: 0,
                  url: _ctx.getPdfFromBlob(_ctx.selectedDocument.file),
                  height: "55vh",
                  width: "100%"
                }, null, 8, ["url"]))
              : (_ctx.isImage(_ctx.selectedDocument))
                ? (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    src: _ctx.getImage(_ctx.selectedDocument.file)
                  }, null, 8, _hoisted_4))
                : (_ctx.isVideo(_ctx.selectedDocument))
                  ? (_openBlock(), _createElementBlock("video", {
                      key: 2,
                      src: _ctx.getImage(_ctx.selectedDocument.file),
                      controls: ""
                    }, null, 8, _hoisted_5))
                  : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, idx) => {
        return (_openBlock(), _createElementBlock("div", { key: idx }, [
          _createElementVNode("div", {
            class: "file-button",
            onClick: ($event: any) => (_ctx.selectFile(item))
          }, [
            _createVNode(_component_el_icon, { size: 32 }, {
              default: _withCtx(() => [
                (
								item.documentType === _ctx.ClientDocumentType.IdentityDocument ||
								item.documentType === _ctx.ClientDocumentType.BiometricValidationDocument
							)
                  ? (_openBlock(), _createBlock(_component_picturefilled, { key: 0 }))
                  : (_openBlock(), _createBlock(_component_document, { key: 1 }))
              ]),
              _: 2
            }, 1024),
            _createElementVNode("span", null, _toDisplayString(item.documentName), 1)
          ], 8, _hoisted_7)
        ]))
      }), 128))
    ])
  ]))
}