import { BaseEntity } from '@/common/models/base.model';

export interface ClientDocument extends BaseEntity {
	documentType: ClientDocumentType;
	documentName: string;
}

export enum ClientDocumentType {
	Proforma = 0,
	Invoice = 1,
	PaymentConfirmation = 2,
	IdentityDocument = 3,
	SignedTermsAndConditions = 4,
	BiometricValidationDocument = 5,
}
