import { UserRole } from '../services/user.service';

export class AppUser {
	JWT: string;
	userName?: string;
	firstName?: string;
	lastName?: string;
	userRoles?: UserRole[];

	constructor(jwt: string) {
		this.JWT = jwt;
		const jwtInfo = this.parseJwt(this.JWT);
		if (jwtInfo) {
			this.userName = jwtInfo.email;
			this.firstName = jwtInfo.given_name;
			this.lastName = jwtInfo.family_name;
			this.userRoles = jwtInfo.role?.split(',').map((role) => {
				return UserRole[role];
			});
		}
	}

	private parseJwt = (token: string): any | null => {
		try {
			return JSON.parse(atob(token.split('.')[1]));
		} catch (e: any) {
			return null;
		}
	};
}
