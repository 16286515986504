<template>
	<el-form ref="form" :model="flatPerson" :rules="formRules" label-position="left" label-width="150px" class="form">
		<el-tabs v-model="activeTab" :stretch="true" :tab-position="tabPosition">
			<el-tab-pane name="personInfo">
				<template #label>
					<span :class="{ 'tab-error': hasPersonInfoErrors }">{{ i18n.getLocalised('person.personInfo') }}</span>
				</template>
				<div class="row">
					<div class="column">
						<el-form-item :label="i18n.getLocalised('person.firstName')" prop="firstName">
							<el-input v-model="flatPerson.firstName" :disabled="readOnly" />
						</el-form-item>
					</div>
					<div class="column">
						<el-form-item :label="i18n.getLocalised('person.lastName')" prop="lastName">
							<el-input v-model="flatPerson.lastName" :disabled="readOnly" />
						</el-form-item>
					</div>
				</div>
				<div class="row">
					<div class="column">
						<el-form-item :label="i18n.getLocalised('person.cnp')" prop="cnp">
							<el-input v-model="flatPerson.cnp" :disabled="readOnly && !allowCriticalChanges" />
						</el-form-item>
					</div>
					<div class="column"></div>
				</div>
				<div class="row">
					<div class="column">
						<el-form-item :label="i18n.getLocalised('person.idCardSeries')" prop="idCardSeries">
							<el-input v-model="flatPerson.idCardSeries" :disabled="readOnly" />
						</el-form-item>
					</div>
					<div class="column">
						<el-form-item :label="i18n.getLocalised('person.idCardNumber')" prop="idCardNumber">
							<el-input v-model="flatPerson.idCardNumber" :disabled="readOnly" />
						</el-form-item>
					</div>
				</div>
				<div class="row">
					<div class="column">
						<el-form-item :label="i18n.getLocalised('person.idCardIssueDate')" prop="idCardIssueDate">
							<el-date-picker
								type="date"
								v-model="flatPerson.idCardIssueDate"
								format="DD.MM.YYYY"
								value-format="YYYY-MM-DD"
								:disabled="readOnly"
							/>
						</el-form-item>
					</div>
					<div class="column">
						<el-form-item :label="i18n.getLocalised('person.idCardExpiryDate')" prop="idCardExpiryDate">
							<el-date-picker
								type="date"
								v-model="flatPerson.idCardExpiryDate"
								format="DD.MM.YYYY"
								value-format="YYYY-MM-DD"
								:disabled="readOnly"
							/>
						</el-form-item>
					</div>
				</div>
				<div class="row">
					<div class="column">
						<el-form-item :label="i18n.getLocalised('person.phoneNumber')" prop="phoneNumber">
							<el-input v-model="flatPerson.phoneNumber" :disabled="readOnly" />
						</el-form-item>
					</div>
					<div class="column">
						<el-form-item :label="i18n.getLocalised('person.email')" prop="email">
							<el-input v-model="flatPerson.email" :disabled="readOnly" type="email" />
						</el-form-item>
					</div>
				</div>
				<div class="row" v-if="!isNew">
					<div class="column">
						<div class="row">
							<div class="column">
								<el-button flat type="default" @click="uploadFileCtrl.click()">{{
									!!flatPerson.idCard
										? i18n.getLocalised('person.uploadNewIdCard')
										: i18n.getLocalised('person.uploadIdCard')
								}}</el-button>
								<input ref="uploadFileCtrl" type="file" @change="uploadFile" accept="image/*,.pdf" />
							</div>
							<div class="column">
								<el-button flat type="default" @click="viewIdCard">
									<el-icon><zoomin /></el-icon>
									{{ i18n.getLocalised('person.showIdCard') }}
								</el-button>
							</div>
						</div>
					</div>
					<div class="column"></div>
				</div>
			</el-tab-pane>
			<el-tab-pane name="personAddress">
				<template #label>
					<span :class="{ 'tab-error': hasAddressErrors }">{{ i18n.getLocalised('person.address') }}</span>
				</template>
				<div class="row">
					<div class="column">
						<el-form-item :label="i18n.getLocalised('person.country')" prop="country">
							<el-input v-model="flatPerson.country" :disabled="readOnly" />
						</el-form-item>
					</div>
					<div class="column">
						<el-form-item :label="i18n.getLocalised('person.county')" prop="county">
							<el-input v-model="flatPerson.county" :disabled="readOnly" />
						</el-form-item>
					</div>
					<div class="column">
						<el-form-item :label="i18n.getLocalised('person.city')" prop="city">
							<el-input v-model="flatPerson.city" :disabled="readOnly" />
						</el-form-item>
					</div>
				</div>
				<div class="row">
					<div class="column">
						<el-form-item :label="i18n.getLocalised('person.street')" prop="street">
							<el-input v-model="flatPerson.street" :disabled="readOnly" />
						</el-form-item>
					</div>
					<div class="column">
						<el-form-item :label="i18n.getLocalised('person.streetNo')" prop="streetNumber">
							<el-input v-model="flatPerson.streetNumber" :disabled="readOnly" />
						</el-form-item>
					</div>
				</div>
				<div class="row">
					<div class="column">
						<el-form-item :label="i18n.getLocalised('person.building')">
							<el-input v-model="flatPerson.building" :disabled="readOnly" />
						</el-form-item>
					</div>
					<div class="column">
						<el-form-item :label="i18n.getLocalised('person.buildingNo')">
							<el-input v-model="flatPerson.buildingNumber" :disabled="readOnly" />
						</el-form-item>
					</div>
				</div>
				<div class="row">
					<div class="column">
						<el-form-item :label="i18n.getLocalised('person.apartment')">
							<el-input v-model="flatPerson.apartment" :disabled="readOnly" />
						</el-form-item>
					</div>
					<div class="column"></div>
				</div>
			</el-tab-pane>
			<el-tab-pane name="contactMethods">
				<template #label>
					<span :class="{ 'tab-error': hasAddressErrors }">{{ i18n.getLocalised('person.contactMethods') }}</span>
				</template>
				<data-grid :table-data="flatPerson.contactMethods">
					<el-table-column prop="type" label="Type">
						<template #default="scope">
							<span>{{ ContactMethodType[scope.row.type] }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="value" label="Value" />
				</data-grid>
			</el-tab-pane>
		</el-tabs>
	</el-form>
	<div class="actions" v-if="!readOnly">
		<el-button circle type="default" @click="cancel">
			<el-icon><close /></el-icon>
		</el-button>
		<el-button circle type="primary" @click="submitForm"
			><el-icon><check /></el-icon
		></el-button>
	</div>
	<el-dialog v-model="showIdCard" :close-on-click-modal="false">
		<img :src="'data:image/png;base64,' + flatPerson.idCard" style="width: 90%; height: 40%" />
	</el-dialog>
</template>

<script lang="ts">
import { emptyAddress } from '@/common/models/address.model';
import { emptyPerson, Person } from '@/common/models/person.model';
import { ContactMethodType } from '@/common/models/contact-method.model';
import { validateEmailAddress, validatePhoneNumber } from '@/common/form-utils';
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from 'vue';
import { uploadIdCard } from '../services/client.service';
import DataGrid from '@/components/data-grid/DataGrid.vue';

const formRulesDefinition = {
	firstName: [{ required: true, message: 'First name is mandatory', trigger: 'blur' }],
	lastName: [{ required: true, message: 'Last name is mandatory', trigger: 'blur' }],
	email: [
		{ required: true, message: 'Email is mandatory', trigger: 'blur' },
		{ validator: validateEmailAddress, trigger: 'blur' },
	],
	phoneNumber: [
		{ required: true, message: 'Phone number is mandatory', trigger: 'blur' },
		{ validator: validatePhoneNumber, trigger: 'blur' },
	],
	cnp: [{ required: true, message: 'CNP is mandatory', trigger: 'blur' }],
	idCardSeries: [{ required: true, message: 'Id Card Series is mandatory', trigger: 'blur' }],
	idCardNumber: [{ required: true, message: 'Id Card Number is mandatory', trigger: 'blur' }],
	idCardIssueDate: [{ required: true, message: 'Issue date is mandatory', trigger: 'blur' }],
	idCardExpiryDate: [{ required: true, message: 'Expiry date is mandatory', trigger: 'blur' }],
	county: [{ required: true, message: 'County is mandatory', trigger: 'blur' }],
	city: [{ required: true, message: 'City is mandatory', trigger: 'blur' }],
	street: [{ required: true, message: 'Street is mandatory', trigger: 'blur' }],
	streetNumber: [{ required: true, message: 'Street number is mandatory', trigger: 'blur' }],
};

const flatify = (person: Person) => {
	return {
		...(({ address, ...rest }) => rest)(person),
		addressId: person.address.id,
		addressCreatedOn: person.address.createdOn,
		addressModifiedOn: person.address.modifiedOn,
		addressCreatedBy: person.address.createdBy,
		addressModifiedBy: person.address.modifiedBy,
		country: person.address.country,
		county: person.address.county,
		city: person.address.city,
		street: person.address.street,
		streetNumber: person.address.streetNumber,
		building: person.address.building,
		buildingNumber: person.address.buildingNumber,
		apartment: person.address.apartment,
		addressIsDeleted: person.address.isDeleted,
		contactMethods: [...person.contactMethods],
		phoneNumber: person.contactMethods?.find(
			(cm) =>
				cm.type === ContactMethodType.Phone &&
				(cm.isPreffered || person.contactMethods.filter((cm) => cm.type === ContactMethodType.Phone).length == 1)
		)?.value,
		email: person.contactMethods?.find(
			(cm) =>
				cm.type === ContactMethodType.Email &&
				(cm.isPreffered || person.contactMethods.filter((cm) => cm.type === ContactMethodType.Email).length == 1)
		)?.value,
	};
};

const unflatify = (flatPerson: any): Person => {
	const result = {
		contactMethods: [...flatPerson.contactMethods],
		firstName: flatPerson.firstName,
		lastName: flatPerson.lastName,
		idCardSeries: flatPerson.idCardSeries,
		idCardNumber: flatPerson.idCardNumber,
		cnp: flatPerson.cnp,
		idCardIssueDate: flatPerson.idCardIssueDate,
		idCardExpiryDate: flatPerson.idCardExpiryDate,
		idCard: null,
		id: flatPerson.id,
		clientCertificates: flatPerson.clientCertificates,
		agreedToTermsAndConditions: flatPerson.agreedToTermsAndConditions,
		agreeToGDPR: flatPerson.agreeToGDPR,
		createdOn: flatPerson.createdOn,
		modifiedOn: flatPerson.modifiedOn,
		createdBy: flatPerson.createdBy,
		modifiedBy: flatPerson.modifiedBy,
		isDeleted: flatPerson.isDeleted,
		address: {
			country: flatPerson.country,
			county: flatPerson.county,
			city: flatPerson.city,
			street: flatPerson.street,
			streetNumber: flatPerson.streetNumber,
			building: flatPerson.building,
			buildingNumber: flatPerson.buildingNumber,
			apartment: flatPerson.apartment,
			id: flatPerson.addressId,
			createdOn: flatPerson.addressCreatedOn,
			modifiedOn: flatPerson.addressModifiedOn,
			createdBy: flatPerson.addressCreatedBy,
			modifiedBy: flatPerson.addressModifiedBy,
			isDeleted: flatPerson.addressIsDeleted,
		},
		phoneNumber: flatPerson.phoneNumber,
		email: flatPerson.email,
	};

	if (!result.contactMethods) result.contactMethods = [];

	if (result.contactMethods.filter((cm) => cm.type === ContactMethodType.Phone).length == 0)
		result.contactMethods.push({
			type: ContactMethodType.Phone,
			value: flatPerson.phoneNumber,
			isPreffered: true,
		});
	else {
		const phone = result.contactMethods.find((cm) => cm.type === ContactMethodType.Phone && cm.isPreffered);
		if (phone) phone.value = flatPerson.phoneNumber;
		else
			result.contactMethods.push({
				type: ContactMethodType.Phone,
				value: flatPerson.phoneNumber,
				isPreffered: true,
			});
	}

	if (result.contactMethods.filter((cm) => cm.type === ContactMethodType.Email).length == 0)
		result.contactMethods.push({
			type: ContactMethodType.Email,
			value: flatPerson.email,
			isPreffered: true,
		});
	else {
		const email = result.contactMethods.find((cm) => cm.type === ContactMethodType.Email && cm.isPreffered);
		if (email) email.value = flatPerson.email;
		else
			result.contactMethods.push({
				type: ContactMethodType.Email,
				value: flatPerson.email,
				isPreffered: true,
			});
	}

	return result;
};

export default defineComponent({
	components: {
		DataGrid,
	},
	emits: ['onSave', 'onCancel'],
	props: ['tabPosition', 'model', 'readOnly', 'personId', 'allowCriticalChanges'],
	setup(props, { emit }) {
		const form = ref();
		const state = reactive({
			clientData: emptyPerson,
			activeTab: 'personInfo',
			isNew: true,
			hasPersonInfoErrors: false,
			hasAddressErrors: false,
			flatPerson: { ...flatify(emptyPerson as Person) },
			tabPosition: props.tabPosition ?? 'top',
			readOnly: false,
			fileData: [],
			showIdCard: false,
		});
		const uploadFileCtrl = ref();

		const formRules = reactive(formRulesDefinition);

		watch(
			() => props.model,
			() => {
				if (props.model) {
					const clientDatastateValue = props.model;
					if (!clientDatastateValue.address) clientDatastateValue.address = emptyAddress;
					state.clientData = clientDatastateValue;
					state.isNew = !state.clientData.id;
					state.flatPerson = { ...flatify(state.clientData as Person) };
					state.readOnly = props.readOnly;
				}
			}
		);

		onMounted(() => {
			if (props.model) {
				const clientDatastateValue = props.model;
				if (!clientDatastateValue.address) clientDatastateValue.address = emptyAddress;
				state.clientData = clientDatastateValue;
				state.isNew = !state.clientData.id;
				state.flatPerson = { ...flatify(state.clientData as Person) };
				state.readOnly = props.readOnly;
			}
		});

		const cancel = () => {
			form.value.resetFields();
			emit('onCancel');
		};

		const submitForm = async () => {
			try {
				const validationResult = await form.value.validate();
				if (validationResult) {
					state.clientData = { ...unflatify(state.flatPerson) };
					emit('onSave', state.clientData);
					// if (state.isNew) form.value.resetFields();
				}
			} catch (err: any) {
				if (err.city || err.county || err.street || err.streetNumber) state.hasAddressErrors = true;
				else state.hasAddressErrors = false;
				if (err.cnp || err.email || err.firstName || err.lastName || err.idCardNumber || err.idCardSeries || err.phoneNumber)
					state.hasPersonInfoErrors = true;
				else state.hasPersonInfoErrors = false;
				return;
			}
		};

		const uploadFile = async (el: any) => {
			await uploadIdCard(props.model.id, el.target.files[0]);
		};

		const viewIdCard = () => {
			state.showIdCard = true;
		};

		const actions = {
			cancel: cancel,
			submitForm: submitForm,
			uploadFile: uploadFile,
			viewIdCard: viewIdCard,
		};

		return {
			...toRefs(state),
			ContactMethodType,
			uploadFileCtrl,
			formRules,
			form,
			...actions,
		};
	},
});
</script>

<style lang="scss" scoped>
.actions {
	padding: 2rem 0 0 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.form {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	.row {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		.column {
			display: flex;
			flex-direction: column;
			flex: 1;

			.el-select {
				width: 100%;
			}
		}
	}
}
.tab-error {
	color: #ff0000;
	&::before {
		content: '*';
	}
}
input[type='file'] {
	display: none;
}
label[for='uploadFile'] {
	border: 1px solid #ccc;
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
}
.clickable {
	cursor: pointer;
}
</style>
