import { BaseEntity } from '@/common/models/base.model';
import { Person } from '@/common/models/person.model';
import { Company } from '@/features/companies/models/company.model';
import { Order } from '@/features/orders/models/order.model';
import { User } from '@/features/user/models/user.model';
import { ClientProduct } from './client-product.model';
import { UserValidationSession } from './user-validation-session.model';

export interface Client extends BaseEntity {
	clientName: string;
	clientType: ClientType;
	kycStatus: KYCStatus | null;
	isValid: boolean;
	users: Array<User>;
	company: Company | null;
	person: Person | null;
	orders: Array<Order>;
	validationSessions: Array<UserValidationSession>;
	purchasedProducts: Array<ClientProduct>;
}

export enum ClientType {
	Person = 0,
	Company = 1,
}

export enum KYCStatus {
	Rejected = 0,
	InProgress = 1,
	Approved = 2,
}

export const emptyClient: Client = {
	id: 0,
	createdBy: null,
	createdOn: null,
	modifiedBy: null,
	modifiedOn: null,
	isDeleted: false,
	users: [],
	clientName: '',
	clientType: ClientType.Person,
	kycStatus: null,
	isValid: false,
	company: null,
	person: null,

	orders: new Array<Order>(),
	validationSessions: new Array<UserValidationSession>(),
	purchasedProducts: new Array<ClientProduct>(),
};
