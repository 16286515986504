import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_divider = _resolveComponent("el-divider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_descriptions, { title: "Order Info" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_descriptions_item, { label: "Email" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.order.email), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions_item, { label: "Phone Number" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.order.phoneNumber), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions_item, { label: "Order Date" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.formatDateTime(_ctx.order.createdOn)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions_item, { label: "Order Status" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.OrderStatus[_ctx.order.orderStatus]), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions_item, { label: "Order Value" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.order.finalValue.price) + " " + _toDisplayString(_ctx.order.finalValue.currencyCode), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions_item, { label: "Payment Status" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.PaymentStatus[_ctx.order.paymentStatus!]), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions_item, { label: "Payment Method" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.PaymentMethod[_ctx.order.paymentMethod!]), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_divider),
    _createVNode(_component_el_descriptions, { title: "Invoice Info" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_descriptions_item, { label: "Person Type" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.PersonType[_ctx.order.personType]), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions_item),
        _createVNode(_component_el_descriptions_item),
        (_ctx.order.personType === _ctx.PersonType.LegalPerson)
          ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
              key: 0,
              label: "Company Name"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.order.companyName), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.order.personType === _ctx.PersonType.LegalPerson)
          ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
              key: 1,
              label: "CUI"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.order.cui), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.order.personType === _ctx.PersonType.LegalPerson)
          ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
              key: 2,
              label: "ONRC#"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.order.onrcNumber), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.order.personType === _ctx.PersonType.LegalPerson)
          ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
              key: 3,
              label: "Is Tax Payer"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.order.isTaxPayer ? 'Yes' : 'No'), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_el_descriptions_item),
        _createVNode(_component_el_descriptions_item),
        _createVNode(_component_el_descriptions_item, { label: "Invoice Address" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.order.invoiceAddress
					? _ctx.order.invoiceAddress.street ??
					  '' + ' ' + _ctx.order.invoiceAddress.streetNumber ??
					  '' + ' ' + _ctx.order.invoiceAddress.building ??
					  '' + ' ' + _ctx.order.invoiceAddress.buildingNumber ??
					  ''
					: '-'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions_item, { label: "City" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.order.invoiceAddress ? _ctx.order.invoiceAddress.city : '-'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions_item, { label: "County" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.order.invoiceAddress ? _ctx.order.invoiceAddress.county : '-'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions_item, { label: "Country" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.order.invoiceAddress ? _ctx.order.invoiceAddress.country : '-'), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_divider),
    _createVNode(_component_el_descriptions, { title: "Delivery Info" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_descriptions_item, { label: "Delivery Method" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.DeliveryMethod[_ctx.order.deliveryMethod!]), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions_item, { label: "Delivery Address" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.order.deliveryAddress
					? _ctx.order.deliveryAddress.street ??
					  '' + ' ' + _ctx.order.deliveryAddress.streetNumber ??
					  '' + ' ' + _ctx.order.deliveryAddress.building ??
					  '' + ' ' + _ctx.order.deliveryAddress.buildingNumber ??
					  ''
					: '-'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions_item, { label: "City" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.order.deliveryAddress ? _ctx.order.deliveryAddress.city : '-'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions_item, { label: "County" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.order.deliveryAddress ? _ctx.order.deliveryAddress.county : '-'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions_item, { label: "Country" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.order.deliveryAddress ? _ctx.order.deliveryAddress.country : '-'), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}