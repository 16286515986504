<template>
	<div class="widget-container">
		<div class="widget">
			<el-card :body-style="{ height: 'calc(50vh - 55px)', 'overflow-y': 'auto' }">
				<template #header>
					<div class="card-header">
						<span>Pending Approval (Clients)</span>
					</div>
				</template>
				<certificate-request-list clients :status="CertificateRequestStatus.PendingApproval.key" height="300px" />
			</el-card>
		</div>
		<div class="widget">
			<el-card :body-style="{ height: 'calc(50vh - 55px)', 'overflow-y': 'auto' }">
				<template #header>
					<div class="card-header">
						<span>Pending Approval (Signatories)</span>
					</div>
				</template>
				<certificate-request-list companies :status="CertificateRequestStatus.PendingApproval.key" height="300px" />
			</el-card>
		</div>
		<div class="widget">
			<el-card :body-style="{ height: 'calc(50vh - 55px)', 'overflow-y': 'auto' }">
				<template #header>
					<div class="card-header">
						<span>Pending Validation (Signatories)</span>
					</div>
				</template>
				<certificate-request-list companies :status="CertificateRequestStatus.PendingValidation.key" height="300px" />
			</el-card>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CertificateRequestList from '@/features/companies/components/CertificateRequestList.vue';
import { CertificateRequestStatus } from '@/features/companies/models/certificate-request.model';


export default defineComponent({
	components: { CertificateRequestList },
	setup() {
		return {
			CertificateRequestStatus
		};
	},
});
</script>

<style scoped lang="scss">
.widget-container {
	display: flex;
	flex-direction: column;
	// flex-flow: column;
	gap: 2rem;
	overflow-y: auto;
	height: 100%;

	.widget {
		flex: 1 1 50%;
	}
}
.card-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
</style>
