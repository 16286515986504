import { BaseEntity, emptyBaseEntity } from '@/common/models/base.model';
import { SimpleUser } from '@/common/models/user-dto.model';
import { ClientDocument } from './client-document.model';
import { Person } from '@/common/models/person.model';

export class UserValidationSession implements BaseEntity {
	id: number | null;
	createdOn: Date | null;
	modifiedOn: Date | null;
	createdBy: SimpleUser | null;
	modifiedBy: SimpleUser | null;
	isDeleted: boolean;
	attachedDocuments: Array<ClientDocument>;
	sessionHash: string;
	status: ValidationSessionStatus | null;
	validationSessionSteps: Array<ValidationSessionStep>;
	personId: number | null;
	person: Person | null;
	certificateRequestId: number | null;
}

export class ValidationSessionStep implements BaseEntity {
	id: number | null;
	createdOn: Date | null;
	modifiedOn: Date | null;
	createdBy: SimpleUser | null;
	modifiedBy: SimpleUser | null;
	isDeleted: boolean;

	comments: string;
	isValid: boolean;
	order: number;
	validatedOn: Date | null;
	validatedBy: SimpleUser | null;
	validationReason: ValidationReason;
}

export const emptyUserValidationSession: UserValidationSession = {
	...emptyBaseEntity,
	attachedDocuments: new Array<ClientDocument>(),
	validationSessionSteps: new Array<ValidationSessionStep>(),
	sessionHash: '',
	status: null,
	personId: null,
	person: null,
	certificateRequestId: null,
};

export enum ValidationSessionStatus {
	PendingValidation = 0,
	Validated = 1,
	Rejected = 2,
	Expired = 3,
}

export enum ValidationReason {
	ClientCreated = 0,
	ValidateEmail = 1,
	ValidatePhone = 2,
	ActivateAccount = 3,
	BiometricValidation = 4,
	VideoValidationSession = 5,
	LivenessValidation = 6,
	InPersonValidation = 7,
}
