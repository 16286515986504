<template>
	<div style="display: flex; flex-direction: row; justify-content: space-between;">
		<el-descriptions>
			<el-descriptions-item label="Session hash">{{ session?.sessionHash }}</el-descriptions-item>
		</el-descriptions>
		<el-button type="primary" @click="changeValidationType" v-if="canChangeValidationSessionType" :disabled="!isOnlineValidation && !isInPersonValidation">
			{{ 
				isOnlineValidation 
					? "Switch to In Person"
					: isInPersonValidation ? "Switch to Online" : "Unknown"
			}}
		</el-button>
	</div>
	<el-tabs v-model="currentTab">
		<el-tab-pane label="Session" name="session">
			<el-timeline>
				<el-timeline-item
					v-for="(item, idx) in data"
					:key="idx"
					:timestamp="formatDateTime(item.validatedOn)"
					:color="getNodeColor(item)"
					:hollow="!item.validatedOn"
					placement="top"
				>
					<el-card>
						<template #header>
							<div class="card-actions">
								<el-button
									type="text"
									size="small"
									v-if="!item.isValid &&
										(!!clientId || !!validateStepFn) &&
										(item.validationReason === 4 ||
										item.validationReason === 5 ||
										item.validationReason === 6 ||
										item.validationReason === 7)
									"
									@click="validate(item)"
								>
									<i class="el-icon-check"></i>
									Validate
								</el-button>
								<el-button
									type="text"
									size="small"
									v-if="(!item.isValid && (!!clientId || !!resendStepFn) && (item.validationReason === 4 || item.validationReason === 5 || item.validationReason === 6)) || item.validationReason === 1"
									@click="resend(item)"
								>
									<i class="el-icon-refresh"></i>
									Resend
								</el-button>
							</div>
						</template>
						<div style="display: flex; flex-direction: row">
							<div style="flex: 1; display: flex; flex-direction: column; justify-content: center; align-items: flex-start">
								<p>{{ ValidationReason[item.validationReason] }}</p>
								<p v-if="item.validatedOn">
									{{ item.isValid ? 'Validated' : 'Rejected' }} by
									{{ item.validatedBy ? item.validatedBy.username : 'N/A' }}
								</p>
								<p v-else>Pending...</p>
							</div>
							<div style="flex: 2; display: flex; flex-direction: column; justify-content: center; align-items: flex-start">
								<p>Comments:</p>
								<p>{{ item.comments || 'No comments' }}</p>
							</div>
						</div>
					</el-card>
				</el-timeline-item>
			</el-timeline>
		</el-tab-pane>
		<el-tab-pane label="Audit" name="audit">
			<el-timeline>
				<el-timeline-item v-for="(item, idx) in sessionLog" :key="idx" :timestamp="formatDateTime(item.createdOn)" placement="top">
					<el-card>
						<div style="display: flex; flex-direction: row">
							<div style="flex: 1; display: flex; flex-direction: column; justify-content: center; align-items: flex-start">
								<p>{{ item.contextType?.code }} - {{ item.eventType?.code }}</p>
							</div>
							<div style="flex: 2; display: flex; flex-direction: column; justify-content: center; align-items: flex-start">
								<p>Originating IP</p>
								<p>{{ item.originatingIp || 'N/A' }}</p>
								<p>Response:</p>
								<p>{{ item.response || 'No response' }}</p>
							</div>
						</div>
					</el-card>
				</el-timeline-item>
			</el-timeline>
		</el-tab-pane>
	</el-tabs>
	<el-dialog v-model="showValidateStep" :close-on-click-modal="false" destroy-on-close>
		<template #header>
			<span>{{ selectedStep ? ValidationReason[selectedStep!.validationReason] : '' }}</span>
		</template>
		<div v-if="selectedStep!.validationReason === ValidationReason.InPersonValidation" class="person-info">
			<div class="personal-info">
				<el-descriptions title="Person Info" size="small" direction="vertical" columns="2">
					<el-descriptions-item :label="i18n.getLocalised('person.firstName')">{{ person?.firstName }}</el-descriptions-item>
					<el-descriptions-item :label="i18n.getLocalised('person.lastName')">{{ person?.lastName }}</el-descriptions-item>
					<el-descriptions-item :label="i18n.getLocalised('person.cnp')" span="2">{{ person?.cnp }}</el-descriptions-item>
					<el-descriptions-item :label="i18n.getLocalised('person.idCardSeries')">{{ person?.idCardSeries }}</el-descriptions-item>
					<el-descriptions-item :label="i18n.getLocalised('person.idCardNumber')">{{ person?.idCardNumber }}</el-descriptions-item>
					<el-descriptions-item :label="i18n.getLocalised('person.idCardIssueDate')">{{ formatDate(person?.idCardIssueDate ?? null) }}</el-descriptions-item>
					<el-descriptions-item :label="i18n.getLocalised('person.idCardExpiryDate')">{{ formatDate(person?.idCardExpiryDate ?? null) }}</el-descriptions-item>
				</el-descriptions>
				<el-descriptions title="Address" size="small" direction="vertical" columns="2">
					<el-descriptions-item :label="i18n.getLocalised('person.street')">{{ person?.address?.street }}</el-descriptions-item>
					<el-descriptions-item :label="i18n.getLocalised('person.streetNo')">{{ person?.address?.streetNumber }}</el-descriptions-item>
					<el-descriptions-item :label="i18n.getLocalised('person.building')">{{ person?.address?.building }}</el-descriptions-item>
					<el-descriptions-item :label="i18n.getLocalised('person.buildingNo')">{{ person?.address?.buildingNumber }}</el-descriptions-item>
					<el-descriptions-item :label="i18n.getLocalised('person.apartment')">{{ person?.address?.apartment }}</el-descriptions-item>
					<el-descriptions-item :label="i18n.getLocalised('person.city')">{{ person?.address?.city }}</el-descriptions-item>
					<el-descriptions-item :label="i18n.getLocalised('person.county')">{{ person?.address?.county }}</el-descriptions-item>
					<el-descriptions-item :label="i18n.getLocalised('person.country')">{{ person?.address?.country }}</el-descriptions-item>
				</el-descriptions>
			</div>
			<div class="id-card">
				<el-image
					style="width: 100%; height: 100%"
					:src="`data:image/png;base64,${person?.idCard}`"
					fit="contain"
					:preview-src-list="`data:image/png;base64,${person?.idCard}`"
				></el-image>
				<div class="actions">
					<el-button flat type="default" @click="uploadFileCtrl.click()">{{
						!!person?.idCard
						? i18n.getLocalised('person.uploadNewIdCard')
						: i18n.getLocalised('person.uploadIdCard')
					}}</el-button>
					<input ref="uploadFileCtrl" type="file" @change="uploadFile" accept="image/*" />
				</div>
			</div>
		</div>
		<el-form>
			<el-form-item label="Comments">
				<el-input type="textarea" v-model="validateStepComments" placeholder="Validation comments"></el-input>
			</el-form-item>
		</el-form>
		<template #footer>
			<div class="actions">
				<div class="left">
					<el-button @click="showValidateStep = false">Cancel</el-button>
				</div>
				<div class="right" v-if="!!clientId || !!validateStepFn">
					<el-button type="success" @click="validateStep(true)" :disabled="!person?.idCard">Approve</el-button>
					<el-button type="danger" @click="validateStep(false)">Reject</el-button>
				</div>
			</div>
		</template>
	</el-dialog>
</template>

<script lang="ts">
import { defineComponent, onMounted, watch, reactive, toRefs, PropType, ref, computed } from 'vue';
import { UserValidationSession, ValidationSessionStep, ValidationReason } from '../models/user-validation-session.model';

import { updateValidationStep, resendStep, getPerson, uploadIdCard, changeUserValidationProcess } from '../services/client.service';
import { formatDateTime, formatDate } from '@/common/component-utils';
import { ValidationSessionLog } from '../models/validation-session-log.model';
import { Person } from '@/common/models/person.model';

interface ISessionEditState {
	data: Array<ValidationSessionStep>;
	showValidateStep: boolean;
	validateStepComments: string;
	selectedStep: ValidationSessionStep | null;
	currentTab: string;
	logs: Array<ValidationSessionLog>;
	person: Person | null;
}

export default defineComponent({
	props: {
		session: Object as PropType<UserValidationSession>,
		sessionLog: Object as PropType<Array<ValidationSessionLog>>,
		clientId: {
			type: Number,
			required: false,
			default: null,
		},
		resendStepFn: {
			type: Function,
			required: false
		},
		validateStepFn: {
			type: Function,
			required: false
		}
	},
	emits: ['onValdiationStatusChange'],
	setup(props, { emit }) {
		const uploadFileCtrl = ref();
		const state = reactive<ISessionEditState>({
			data: new Array<ValidationSessionStep>(),
			showValidateStep: false,
			validateStepComments: '',
			selectedStep: null,
			currentTab: 'session',
			logs: new Array<ValidationSessionLog>(),
			person: null,
		});

		const isOnlineValidation = computed(() => {
			return !!props.session?.validationSessionSteps?.find(f => f.validationReason === ValidationReason.BiometricValidation);
		});

		const isInPersonValidation = computed(() => {
			return !!props.session?.validationSessionSteps?.find(f => f.validationReason === ValidationReason.InPersonValidation);
		});

		watch(
			() => props.session,
			() => {
				console.log('Session changed', props.session);
				loadSession();
			}
		);

		const canChangeValidationSessionType = computed(() => {
			let step = props.session?.validationSessionSteps?.find(f => f.validationReason === ValidationReason.BiometricValidation);
			if(!step) {
				step = props.session?.validationSessionSteps?.find(f => f.validationReason === ValidationReason.InPersonValidation);
			}

			return !step?.isValid
		});

		onMounted(() => {
			loadSession();
		});

		const loadSession = () => {
			if (props.session && props.session.validationSessionSteps) {
				state.data = [...props.session.validationSessionSteps].sort((first, second) => {
					return first.order > second.order ? 1 : first.order < second.order ? -1 : 0;
				});
			}
			if (props.sessionLog) {
				state.logs = [...props.sessionLog].sort((first, second) => {
					return first.createdOn > second.createdOn ? 1 : first.createdOn < second.createdOn ? -1 : 0;
				});
			}
		};

		const getNodeColor = (item: ValidationSessionStep) => {
			if (!item.validatedOn) return '#c8c9cc';
			if (item.isValid) return '#67c23a';
			if (!item.isValid) return '#f56c6c';
		};

		const validate = async (item: ValidationSessionStep) => {
			state.showValidateStep = true;
			state.validateStepComments = '';
			state.selectedStep = item;
			if(!state.person)
				await loadPerson();
		};

		const loadPerson = async () => {
			state.person = await getPerson(props.session!.personId!);
		};

		const resend = async (item: ValidationSessionStep) => {
			if(props.resendStepFn) {
				await props.resendStepFn(props.session!.id!, item.id!);
				return;
			}
			await resendStep(props.clientId, props.session!.id!, item.id!);
		};

		const validateStep = async (resolution: boolean) => {
			if(props.clientId)
				await updateValidationStep(props.clientId, props.session!.id!, state.selectedStep!.id, resolution, state.validateStepComments);
			else if(props.validateStepFn)
				await props.validateStepFn(props.session!.id!, state.selectedStep!.id, resolution, state.validateStepComments);

			state.showValidateStep = false;
			emit('onValdiationStatusChange');
		};

		const uploadFile = async (el: any) => {
			await uploadIdCard(state.person!.id!, el.target.files[0]);
			await loadPerson();
		};

		const changeValidationType = async () => {
			await changeUserValidationProcess(props.session!.id!);
			emit('onValdiationStatusChange');
		};

		return {
			...toRefs(state),
			isOnlineValidation,
			isInPersonValidation,
			canChangeValidationSessionType,
			uploadFileCtrl,
			ValidationReason,
			getNodeColor,
			validate,
			resend,
			validateStep,
			formatDateTime,
			formatDate,
			uploadFile,
			changeValidationType
		};
	},
});
</script>

<style scoped lang="scss">
.card-actions {
	display: flex;
	justify-content: flex-end;
}

.actions {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.right {
		display: flex;
		justify-content: flex-end;
		gap: 1rem;
	}
}

.person-info {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	margin-bottom: 1rem;

	.personal-info {
		flex: 1;
	}
	.id-card {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

input[type='file'] {
	display: none;
}
label[for='uploadFile'] {
	border: 1px solid #ccc;
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
}
</style>
