import { environment } from '@/common/environment';
import { usePatch, useGet } from '@/common/services/api.service';
import { Order } from '../models/order.model';

const baseUrl = `${environment.apiUrl}/orders`;

const getOrders = async () => {
	return await useGet<Array<Order>>(baseUrl);
};

const getOrder = async (orderId: number) => {
	return await useGet<Order>(`${baseUrl}/${orderId}`);
};

const changePaymentStatus = async (
	orderId: number,
	paymentStatus: number,
	comments: string | null,
	documentNumber: string | null,
	generateInvoice: boolean
) => {
	await usePatch(`${baseUrl}/${orderId}/payment-status`, {
		status: paymentStatus,
		observations: comments,
		documentNumber,
		generateInvoice,
	});
};

const markOrderAsFulfilled = async (orderId: number) => {
	await usePatch(`${baseUrl}/${orderId}/mark-as-fulfilled`, null);
};

const sendOrderToFulfillment = async (orderId: number) => {
	await usePatch(`${baseUrl}/${orderId}/resend-to-fulfillment`, null);
};

const resendCertificateSetupEmail = async (orderId: number) => {
	await usePatch(`${baseUrl}/${orderId}/resend-certificate-email`, null);
};

export { changePaymentStatus, resendCertificateSetupEmail, getOrders, getOrder, markOrderAsFulfilled, sendOrderToFulfillment };
