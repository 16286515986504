<template>
	<div class="actions">
		<el-button-group>
			<el-button
				type="primary"
				size="small"
				@click="changeStatusDialogVisible = true"
				v-if="order.orderStatus === OrderStatus.Payment && order.paymentStatus !== PaymentStatus.Paid"
				>Change Payment Status</el-button
			>
			<el-button type="primary" size="small" @click="markAsFulfilled" v-if="order.orderStatus === OrderStatus.PendingFullfillment"
				>Mark as fulfilled</el-button
			>
			<el-button type="primary" size="small" @click="sendToFulfillment" v-if="order.orderStatus === OrderStatus.PendingFullfillment || order.orderStatus === OrderStatus.Fulfilled"
				>Send order to fulfillment</el-button
			>
			<el-button
				type="primary"
				size="small"
				@click="resendCertificateEmail"
				v-if="order.orderStatus === OrderStatus.PendingFullfillment"
				>Resend Certiticate Email</el-button
			>
		</el-button-group>
	</div>
	<el-tabs v-model="activeTab">
		<el-tab-pane label="Order Info" name="orderInfo">
			<order-info :order-info="order"></order-info>
		</el-tab-pane>
		<el-tab-pane label="Order Items" name="orderItems">
			<order-item-list :order-items="order.orderItems"></order-item-list>
		</el-tab-pane>
		<el-tab-pane label="Order Documents" name="orderDocuments">
			<order-documents :order-documents="order.orderDocuments"></order-documents>
		</el-tab-pane>
		<el-tab-pane label="Client Info" name="clientInfo">
			<div class="actions">
				<el-button link type="primary" size="small" @click="openClient">Open Client<el-icon style="margin-left: 0.3rem;"><icon-switch /></el-icon></el-button>
			</div>
			<client-info :tabPosition="'top'" :model="order.client.person" :read-only="true"></client-info>
		</el-tab-pane>
		<el-tab-pane label="Client Documents" name="clientDocuments">
			<client-documents v-if="order.client?.id" :client-id="order.client.id"></client-documents>
		</el-tab-pane>
		<el-tab-pane label="Validation Session" name="validationSession">
			<validation-session-edit
				v-if="!!order.orderSession"
				:session="order.orderSession"
				:session-log="validationSessionLog"
				:client-id="order.client.id!"
				@on-valdiation-status-change="reloadOrder"
			></validation-session-edit>
			<span v-else>No validation session</span>
		</el-tab-pane>
		<el-tab-pane label="Certificate Request" name="certificateRequest" v-if="!!order.certificateRequestId">
			<certificate-request-details :model="order.certificateRequest"></certificate-request-details>
		</el-tab-pane>
	</el-tabs>
	<el-dialog title="Change Status" width="500px" v-model="changeStatusDialogVisible" :append-to-body="true" :close-on-click-modal="false">
		<div class="container">
			<el-form>
				<el-form-item label="New status">
					<el-select v-model="paymentStatus" placeholder="Select status">
						<el-option label="Pending Payment" :value="1"></el-option>
						<el-option label="Partially Paid" :value="2"></el-option>
						<el-option label="Paid" :value="3"></el-option>
						<el-option label="Cancelled" :value="4"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="Comments">
					<el-input type="textarea" v-model="paymentNote" placeholder="Payment Note"></el-input>
				</el-form-item>
				<el-form-item>
					<el-switch
						v-model="generateInvoice"
						active-color="#13ce66"
						inactive-color="#ff4949"
						active-text="Generate invoice"
					></el-switch>
				</el-form-item>
				<el-form-item label="Invoice Number" v-if="!generateInvoice">
					<el-input v-model="invoiceNumber" placeholder="Invoice Number"></el-input>
				</el-form-item>
			</el-form>
		</div>
		<div class="actions space-between">
			<el-button round type="default" @click="cancelChangeStatus">
				<el-icon><close /></el-icon>
				Cancel</el-button
			>
			<el-button round type="primary" @click="submitForm"
				><el-icon><check /></el-icon>Confirm</el-button
			>
		</div>
	</el-dialog>
</template>

<script lang="ts">
import { defineComponent, reactive, Ref, toRef, toRefs, watch } from 'vue';
import { ElNotification } from 'element-plus';

import OrderInfo from './OrderInfo.vue';
import OrderItemList from './OrderItemList.vue';
import ValidationSessionEdit from '@/features/clients/components/ValidationSessionEdit.vue';
import CertificateRequestDetails from '@/features/companies/components/CertificateRequestDetails.vue';
import ClientDocuments from '@/features/clients/components/ClientDocuments.vue';
import ClientInfo from '@/features/clients/components/ClientInfo.vue';
import OrderDocuments from './OrderDocuments.vue';

import { changePaymentStatus, resendCertificateSetupEmail, markOrderAsFulfilled, sendOrderToFulfillment } from '../services/order.service';
import { Order, OrderStatus, PaymentStatus } from '../models/order.model';
import { ValidationSessionLog } from '@/features/clients/models/validation-session-log.model';
import router from '@/router';

export default defineComponent({
	components: { OrderInfo, OrderItemList, ValidationSessionEdit, ClientDocuments, OrderDocuments, CertificateRequestDetails, ClientInfo },
	props: ['model', 'validationSessionLog'],
	emits: ['onOrderChanged'],
	setup(props, { emit }) {
		const order = toRef(props, 'model') as Ref<Order>;
		const state = reactive({
			activeTab: 'orderInfo',
			changeStatusDialogVisible: false,
			paymentStatus: null,
			paymentNote: null,
			generateInvoice: true,
			invoiceNumber: null,
			validationSessionLog: new Array<ValidationSessionLog>(),
		});

		watch(
			() => [props.model, props.validationSessionLog],
			() => {
				order.value = props.model;
				state.validationSessionLog = props.validationSessionLog;
			}
		);

		const submitForm = async () => {
			if (!state.paymentStatus) {
				return;
			}
			if (!state.generateInvoice && !state.invoiceNumber) {
				return;
			}
			await changePaymentStatus(order.value.id!, state.paymentStatus, state.paymentNote, state.invoiceNumber, state.generateInvoice);
			cancelChangeStatus();
			emit('onOrderChanged');
		};

		const reloadOrder = () => {
			emit('onOrderChanged');
		};

		const cancelChangeStatus = () => {
			state.changeStatusDialogVisible = false;
			state.paymentStatus = null;
			state.paymentNote = null;
			state.generateInvoice = true;
		};

		const resendCertificateEmail = async () => {
			try {
				await resendCertificateSetupEmail(order.value.id!);
				ElNotification({
					type: 'success',
					message: 'Sent email',
					title: 'Success',
					zIndex: 10000,
				});
			} catch (err: any) {
				ElNotification({
					type: 'error',
					message: `Error: ${err?.data.code}`,
					title: 'Failed to send email',
					zIndex: 10000,
				});
			}
		};

		const markAsFulfilled = async () => {
			try {
				await markOrderAsFulfilled(order.value.id!);
				ElNotification({
					type: 'success',
					message: 'Order marked as fulfilled',
					title: 'Success',
					zIndex: 10000,
				});
			} catch (err: any) {
				ElNotification({
					type: 'error',
					message: `Error: ${err.message}`,
					title: 'Failed to mark order as fulfilled',
					zIndex: 10000,
				});
			}
		};

		const sendToFulfillment = async () => {
			try {
				await sendOrderToFulfillment(order.value.id!);
				ElNotification({
					type: 'success',
					message: 'Order sent to fulfillment',
					title: 'Success',
					zIndex: 10000,
				});
			} catch (err: any) {
				ElNotification({
					type: 'error',
					message: `Error: ${err.message}`,
					title: 'Failed to send order to fulfillment',
					zIndex: 10000,
				});
			}
		};

		const openClient = () => {
			router.push({ name: 'ClientDetails', params: { id: order.value.client.id! } });
		};

		const actions = {
			submitForm,
			cancelChangeStatus,
			resendCertificateEmail,
			reloadOrder,
			markAsFulfilled,
			sendToFulfillment,
			openClient,
		};

		return {
			order,
			OrderStatus,
			PaymentStatus,
			...toRefs(state),
			...actions,
		};
	},
});
</script>

<style scoped lang="scss">
.actions {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	&.space-between {
		justify-content: space-between;
	}
}

.container {
	display: flex;
	flex-direction: column;
	max-width: 400px;
	align-items: center;
	margin: 0 auto;
}
</style>
