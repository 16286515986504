<template>
	<el-card class="card" :body-style="{ height: 'calc(100% - 109px)', 'overflow-y': 'auto' }">
		<template #header>
			<div class="card-header">
				<span>Orders</span>
			</div>
		</template>
		<div>
			<data-grid
				:table-data="tableData"
				:filter-func="filter"
				height="600"
				width="900"
				:default-sort="{ prop: 'createdOn', order: 'descending' }"
				highlight-current-row
				:show-details="openOrder"
				:show-refresh="true"
				:initial-state="initialGridState"
				@on-refresh="refresh"
				@on-state-change="gridStateChanged"
			>
				<el-table-column label="Order No" sortable="custom" sort-by="orderName">
					<template #default="scope">
						<span>{{ scope.row.orderName }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="createdOn" label="Created On" sortable="custom">
						<template #default="scope">
							<span>{{ formatDateTime(scope.row.createdOn) }}</span>
						</template>
					</el-table-column>
				<el-table-column prop="companyName" label="Invoice" sortable="custom">
					<template #default="scope">
						<span>{{ scope.row.companyName || scope.row.client.clientName }}</span>
					</template>
				</el-table-column>
				<el-table-column label="Order Status" sortable="custom" sort-by="orderStatus">
					<template #default="scope">
						<span>{{ OrderStatus[scope.row.orderStatus] }}</span>
					</template>
				</el-table-column>
				<el-table-column label="Payment Method" sortable="custom" sort-by="paymentMethod">
					<template #default="scope">
						<span>{{ PaymentMethod[scope.row.paymentMethod] }}</span>
					</template>
				</el-table-column>
				<el-table-column label="Payment Status" sortable="custom" sort-by="paymentStatus">
					<template #default="scope">
						<span>{{ PaymentStatus[scope.row.paymentStatus] }}</span>
					</template>
				</el-table-column>
				<el-table-column label="CNP" sortable="custom" sort-by="client.person.cnp">
					<template #default="scope">
						<span>{{ scope.row.client.person?.cnp ?? '-' }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="client.clientName" label="Name" sortable="custom"></el-table-column>
			</data-grid>
		</div>
	</el-card>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import router from '@/router';

import DataGrid from '@/components/data-grid/DataGrid.vue';
import { formatDateTime } from '@/common/component-utils';

import { Order, OrderStatus, PaymentMethod, PaymentStatus } from './models/order.model';
import * as OrderSvc from './services/order.service';

export default defineComponent({
	components: {
		DataGrid,
	},
	setup() {
		const tableData = ref<Array<Order>>([]);
		const initialGridState = ref<any>({});

		if (location.hash) {
			initialGridState.value = location.hash.substring(1).split('&').reduce((acc, curr) => {
				const [key, value] = curr.split('=');
				acc[key] = value;
				return acc;
			}, {});
		}

		onMounted(async () => {
			console.log('mounted', location.hash);
			tableData.value = await OrderSvc.getOrders();
		});

		const filter = (f: Order, value: string) => {
			return (
				f.orderName.indexOf(value) >= 0 ||
				f.client.clientName.indexOf(value) >= 0 ||
				(f.client.person &&
					(f.client.person.contactMethods.filter((f) => f.value?.indexOf(value) >= 0).length > 0 ||
						f.client.person.cnp.indexOf(value) >= 0 ||
						f.client.person.firstName.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
						f.client.person.lastName.toLowerCase().indexOf(value.toLowerCase()) >= 0))
			);
		};

		const openOrder = (row: any) => {
			router.push({ name: 'OrderEdit', params: { id: row.id } });
		};

		const refresh = async () => {
			tableData.value = await OrderSvc.getOrders();
		};

		const gridStateChanged = (state: any) => {
			let hash = '';
			for(let prop in state) {
				hash += `${prop}=${state[prop]}&`;
			}
			location.hash = hash.substring(0, hash.length - 1);
			console.log(state);
		};

		return {
			tableData,
			initialGridState,
			OrderStatus,
			PaymentMethod,
			PaymentStatus,
			filter,
			formatDateTime,
			openOrder,
			refresh,
			gridStateChanged
		};
	},
});
</script>

<style lang="scss" scoped>
.card {
	height: 100%;
	width: 100%;

	.el-card {
		height: 100%;
	}

	.card-header {
		display: flex;
		font-size: 1.2rem;
		font-weight: 600;
	}
}
</style>
