import axios from 'axios';
import { getUser } from './user.service';
import { ElLoading } from 'element-plus';

let loader: any;
let loaderTimer: any;

const useGet = async <T>(
	url: string,
	options: { useAuth?: boolean; useLoader?: boolean; params?: any } = { useAuth: true, useLoader: true, params: {} },
	headers: any = {}
): Promise<T> => {
	try {
		if (options.useLoader)
			loader = ElLoading.service({
				lock: true,
				text: 'Loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'loader-mask',
			});
		const useAuth = options.useAuth ?? true;
		const user = useAuth ? getUser() : null;
		headers = {
			...headers,
			'Cache-Control': 'no-cache',
		};
		const response = await axios.get(url, {
			headers: user
				? {
						Authorization: `Bearer ${user.JWT}`,
						...headers,
				  }
				: { ...headers },
			params: options.params,
		});
		if (response.status >= 400) {
			throw response.data;
		}
		const data = response.data as T;
		return data;
	} catch (err: any) {
		throw err.response?.data;
	} finally {
		loaderTimer = setTimeout(() => loader.close(), 500);
	}
};

const downloadFile = async (
	url: string,
	options: { useAuth: boolean; useLoader: boolean } = { useAuth: true, useLoader: true },
	headers: Array<any> = []
): Promise<{ file: Blob; fileName: string }> => {
	try {
		if (options.useLoader)
			loader = ElLoading.service({
				lock: true,
				text: 'Loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'loader-mask',
			});
		const user = options.useAuth ? getUser() : null;
		const response = await axios.get(url, {
			responseType: 'blob',
			headers: user
				? {
						Authorization: `Bearer ${user.JWT}`,
						...headers,
				  }
				: { ...headers },
		});
		if (response.status >= 400) {
			throw response.data;
		}
		const data = response.data as Blob;
		const fileName = response.headers['content-disposition']?.split(';')[1].split('=')[1];
		return { file: data, fileName: fileName };
	} catch (err: any) {
		throw err.response?.data;
	} finally {
		loaderTimer = setTimeout(() => loader.close(), 500);
	}
};

const usePost = async <T>(
	url: string,
	body: any,
	options: { useAuth: boolean; useLoader: boolean } = { useAuth: true, useLoader: true },
	headers: Array<any> = []
): Promise<T> => {
	try {
		if (options.useLoader)
			loader = ElLoading.service({
				lock: true,
				text: 'Loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'loader-mask',
			});
		const user = options.useAuth ? getUser() : null;
		const response = await axios.post(url, body, {
			headers: user
				? {
						Authorization: `Bearer ${user.JWT}`,
						...headers,
				  }
				: { ...headers },
		});
		if (response.status >= 400) {
			throw response.data;
		}
		const data = response.data as unknown as T;
		return data;
	} catch (err: any) {
		throw err.response?.data;
	} finally {
		loaderTimer = setTimeout(() => loader.close(), 500);
	}
};

const usePut = async <T>(
	url: string,
	body: any,
	options: { useAuth: boolean; useLoader: boolean } = { useAuth: true, useLoader: true },
	headers: Array<any> = []
): Promise<T> => {
	try {
		if (options.useLoader)
			loader = ElLoading.service({
				lock: true,
				text: 'Loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'loader-mask',
			});
		const user = options.useAuth ? getUser() : null;
		const response = await axios.put(url, body, {
			headers: user
				? {
						Authorization: `Bearer ${user.JWT}`,
						...headers,
				  }
				: { ...headers },
		});
		if (response.status >= 400) {
			throw response.data;
		}
		const data = response.data as unknown as T;
		return data;
	} catch (err: any) {
		throw err.response?.data;
	} finally {
		loaderTimer = setTimeout(() => loader.close(), 500);
	}
};

const usePatch = async <T>(
	url: string,
	body: any,
	options: { useAuth: boolean; useLoader: boolean } = { useAuth: true, useLoader: true },
	headers: Array<any> = []
): Promise<T> => {
	try {
		if (options.useLoader)
			loader = ElLoading.service({
				lock: true,
				text: 'Loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'loader-mask',
			});
		const user = options.useAuth ? getUser() : null;
		const response = await axios.patch(url, body, {
			headers: user
				? {
						Authorization: `Bearer ${user.JWT}`,
						...headers,
				  }
				: { ...headers },
		});
		const data = response.data as unknown as T;
		return data;
	} catch (err: any) {
		throw err.response?.data;
	} finally {
		loaderTimer = setTimeout(() => loader.close(), 500);
	}
};

const useDelete = async (
	url: string,
	options: { useAuth: boolean; useLoader: boolean } = { useAuth: true, useLoader: true },
	headers: Array<any> = []
): Promise<void> => {
	try {
		if (options.useLoader)
			loader = ElLoading.service({
				lock: true,
				text: 'Loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'loader-mask',
			});
		const user = options.useAuth ? getUser() : null;
		const response = await axios.delete(url, {
			headers: user
				? {
						Authorization: `Bearer ${user.JWT}`,
						...headers,
				  }
				: { ...headers },
		});
		if (response.status >= 400) {
			throw response.data;
		}
		return Promise.resolve();
	} catch (err: any) {
		throw err.response?.data;
	} finally {
		loaderTimer = setTimeout(() => loader.close(), 500);
	}
};

export { useGet, downloadFile, usePost, usePut, usePatch, useDelete };
